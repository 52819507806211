let constant = {};

constant.ROUTERS = {
	HOME: "/",
	QR: "/qr",
	QR_TEST: "/qr-test",
	COUNTDOWN: "/thong-bao",
	INVALID_CODE: "/khong-hop-le",
	FOR_TEST: "/test",
	AUTHORIZE: "/authorize",

	LUCKY_DRAW_LOGIN: "/admin/login",
	LUCKY_DRAW: "/admin/quay-so",
};

constant.COOKIE_EXPIRES_TIME = 6 / 24; // 1 hour

export default constant;
