import React from "react";
import Cookies from "js-cookie";
import { Button, Container, Paper, TextField, Typography } from "@mui/material";
import { useRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import _ from "lodash";

import "./Test.scss";

import FullLayout from "../../layouts/FullLayout";

import { userTestAtom } from "../../stores/user_test";

import constant from "../../utils/constant";

function Test() {
	document.body.classList.remove("no-footer");

	const [userTest, setUserTest] = useRecoilState(userTestAtom);

	const { register, handleSubmit } = useForm();

	const onSubmit = async (data) => {
		console.log("data :>> ", data);
		if (["0778877946", "0989870925", "0981817654", "0776531501", "0777989983", "0936663594", "0933939169", "0983010283", "0847680691", "0328544461", "0784505571"].includes(data.phone) && data.password == "adtima@2023") {
			Cookies.set("userTest", JSON.stringify(data), {
				expires: constant.COOKIE_EXPIRES_TIME,
			});
			setUserTest(data);
		}
	};

	return (
		<FullLayout bodyClass="pc" title="Test" des="">
			<Container maxWidth="xs">
				<Paper elevation={3} style={{ padding: "20px", marginTop: "50px" }}>
					{userTest && (
						<Typography variant="h5" className="mb-10" style={{ textAlign: "center" }}>
							Đăng nhập môi trường test thành công
						</Typography>
					)}
					{!userTest && (
						<>
							<Typography variant="h5" className="mb-10" style={{ marginBottom: "15px" }}>
								Đăng nhập môi trường test
							</Typography>
							<TextField
								fullWidth
								type="text"
								label="Số điện thoại *"
								className="icon i-phone"
								{...register("phone", {
									required: true,
									maxLength: 10,
									minLength: 10,
									pattern: /(03|05|07|08|09)+([0-9]{8})\b/,
								})}
								maxLength="10"
								inputMode="tel"
								style={{ marginBottom: "15px" }}
							/>
							<TextField
								fullWidth
								type="Mật khẩu *"
								{...register("password", {
									required: true,
									maxLength: 50,
									minLength: 1,
									pattern: /[a-zA-Z0-9]/,
								})}
								label="Mật khẩu"
								style={{ marginBottom: "15px" }}
							/>
							<Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)} style={{ marginLeft: "auto", marginRight: "auto", display: "block" }}>
								Đăng Nhập
							</Button>
						</>
					)}
				</Paper>
			</Container>
		</FullLayout>
	);
}

export default Test;
