import moment from "moment";

import constant from "./constant";

let campaign = {};

campaign.currentDate = function (f = "YYYY-MM-DD HH:mm:ss") {
	return moment().format(f);
};

campaign.convertDate = function (s, f = "YYYY-MM-DD HH:mm:ss") {
	return moment(s).format(f);
};

campaign.convertDateNew = function (s, f = "YYYY-MM-DD HH:mm:ss") {
	return moment(s, "YYYY-MM-DD").format(f);
};

campaign.padText = function (text = "", chars = "x", n = 7) {
	return text.substring(0, n).padEnd(10, chars);
};

campaign.convertPage = function (total = 1, limit = 1) {
	total = parseInt(total);
	limit = parseInt(limit);
	if (limit < 1) {
		return 0;
	}
	// limit = 50;
	let page = Math.ceil(total / limit);
	// console.log(total, "total");
	// console.log(page, "page");
	// console.log(limit, "limit");
	return page;
	return page - 1;
	// if (page > 2) {
	// 	return page - 1;
	// } else {
	// 	return page;
	// }
};

campaign.parseInteger = function (num, signed = false) {
	num = parseInt(num);
	return isNaN(num) ? 0 : signed ? num : Math.abs(num);
};

campaign.countNumberItemRedeem = function (total, value) {
	try {
		// console.log(total, "total");
		// console.log(value, "value");
		if (value == 0 || value == "0") {
			return 0;
		}
		let num = parseInt(total) / parseInt(value);
		num = Math.floor(num);
		console.log(num, "num");
		num = num > 10 ? 10 : num;
		return num;
	} catch (error) {
		return 0;
	}
};

campaign.convertNumberFromStringObject = function (string, field = "point") {
	try {
		if (string) {
			// string = '{"distance":1137.99,"unit":"kilometers","point":1137}';
			const newString = JSON.parse(string);
			if (field == "point") {
				const point = parseInt(newString?.point);
				return isNaN(point) ? 0 : point.toLocaleString();
			}
			if (field == "distance") {
				const distance = parseFloat(newString?.distance);
				return isNaN(distance) ? 0 : distance.toLocaleString();
			}
			return newString?.[field];
		} else {
			return 0;
		}
	} catch (error) {
		return 0;
	}
};

campaign.randomLatLong = function () {
	try {
		// const latitude = Math.random() * 180 - 90;
		// const longitude = Math.random() * 360 - 180;
		const minLatitude = 8.1758; // Southernmost point of Vietnam
		const maxLatitude = 23.3934; // Northernmost point of Vietnam
		const minLongitude = 102.1446; // Westernmost point of Vietnam
		const maxLongitude = 109.4694; // Easternmost point of Vietnam

		// Generate a random latitude within the defined range
		const latitude = Math.random() * (maxLatitude - minLatitude) + minLatitude;

		// Generate a random longitude within the defined range
		const longitude = Math.random() * (maxLongitude - minLongitude) + minLongitude;

		return { latitude: latitude, longitude: longitude };
	} catch (error) {
		return 0;
	}
};

campaign.ignoreRouter = function () {
	try {
		const listRouter = [
			constant.ROUTERS.TNC,
			constant.ROUTERS.TERMS,
			constant.ROUTERS.HOME,
			constant.ROUTERS.PC,
			constant.ROUTERS.COUNTDOWN,
			constant.ROUTERS.FOR_TEST,
			constant.ROUTERS.OPEN_ZALO,
			constant.ROUTERS.WINNER_LIST,
			constant.ROUTERS.NOTI_OVER_18,
		];
		const currentRoute = window.location.pathname;
		if (listRouter.includes(currentRoute)) {
			return true;
		}

		return false;
	} catch (error) {
		return 0;
	}
};

campaign.redirectPathSearch = function (redirectPath, searchURL) {
	try {
		if (!redirectPath) return searchURL;

		if (searchURL?.includes("redirect_path=")) return searchURL;

		return searchURL ? `${searchURL}&redirect_path=${redirectPath}` : `?redirect_path=${redirectPath}`;
	} catch (error) {
		console.error(error);
		return searchURL;
	}
};

campaign.requireLoginRouter = function () {
	try {
		const listRouter = ["/rule", "/zapps/685771351622425549/rule"];
		const currentRoute = window.location.pathname;
		if (listRouter.includes(currentRoute)) {
			return true;
		}

		return false;
	} catch (error) {
		return 0;
	}
};

campaign.activeRoute = function (pathname = "", routecheck = []) {
	try {
		// console.log(pathname, "pathname");
		// console.log(routecheck, "routecheck");
		let is_active = false;
		if (pathname && routecheck) {
			routecheck.forEach((rot) => {
				if (pathname.indexOf(rot) > -1) {
					is_active = true;
				}
			});
		}
		if (is_active == false) {
			return "";
		} else {
			return "active";
		}
	} catch (error) {
		return "";
	}
};

campaign.checkListUrlRedirect = function (redirect) {
	try {
		const listRouter = ["rule", "redeem", "update-info"];

		if (listRouter.includes(redirect)) {
			return true;
		}

		return false;
	} catch (error) {
		return 0;
	}
};

const getWeekInfo = function (type, inputDate) {
	const weekData = [
		{ week_number: 1, confirm_date: "2023/10/19 18:00", start_date: "2023/10/01", end_date: "2023/10/22", winner_end_date: "2023/10/22" }, // for test
		{ week_number: 1, confirm_date: "2023/10/29 18:00", start_date: "2023/10/23", end_date: "2023/10/28", winner_end_date: "2023/10/29" },
		{ week_number: 2, confirm_date: "2023/11/05 18:00", start_date: "2023/10/29", end_date: "2023/11/04", winner_end_date: "2023/11/05" },
		{ week_number: 3, confirm_date: "2023/11/12 18:00", start_date: "2023/11/05", end_date: "2023/11/11", winner_end_date: "2023/11/12" },
		{ week_number: 4, confirm_date: "2023/11/19 18:00", start_date: "2023/11/12", end_date: "2023/11/18", winner_end_date: "2023/11/19" },
		{ week_number: 5, confirm_date: "2023/11/26 18:00", start_date: "2023/11/19", end_date: "2023/11/25", winner_end_date: "2023/11/26" },
		{ week_number: 6, confirm_date: "2023/12/03 18:00", start_date: "2023/11/26", end_date: "2023/12/02", winner_end_date: "2023/12/03" },
		{ week_number: 7, confirm_date: "2023/12/10 18:00", start_date: "2023/12/03", end_date: "2023/12/09", winner_end_date: "2023/12/10" },
		{ week_number: 8, confirm_date: "2023/12/17 18:00", start_date: "2023/12/10", end_date: "2023/12/16", winner_end_date: "2023/12/17" },
		{ week_number: 9, confirm_date: "2023/12/24 18:00", start_date: "2023/12/17", end_date: "2023/12/23", winner_end_date: "2023/12/24" },
		{ week_number: 10, confirm_date: "2023/12/31 18:00", start_date: "2023/12/24", end_date: "2023/12/30", winner_end_date: "2023/12/31" },
		{ week_number: 11, confirm_date: "2024/01/07 18:00", start_date: "2023/12/31", end_date: "2024/01/06", winner_end_date: "2024/01/07" },
		{ week_number: 12, confirm_date: "2024/01/14 18:00", start_date: "2024/01/07", end_date: "2024/01/13", winner_end_date: "2024/01/14" },
		{ week_number: 13, confirm_date: "2024/01/21 18:00", start_date: "2024/01/14", end_date: "2024/01/20", winner_end_date: "2024/01/21" },
		{ week_number: 14, confirm_date: "2024/01/28 18:00", start_date: "2024/01/21", end_date: "2024/01/27", winner_end_date: "2024/01/28" },
		{ week_number: 15, confirm_date: "2024/02/04 18:00", start_date: "2024/01/28", end_date: "2024/02/03", winner_end_date: "2024/02/04" },
	];

	for (const weekInfo of weekData) {
		if (type === "personal" && inputDate >= weekInfo.start_date && inputDate <= weekInfo.end_date) {
			const currentDate = moment().format("YYYY/MM/DD HH:mm");
			let confirmStatus = "done";
			if (currentDate <= weekInfo.confirm_date) confirmStatus = "waiting";
			return {
				...weekInfo,
				start_date: campaign.convertDateNew(weekInfo.start_date, "DD/MM"), //format date
				end_date: campaign.convertDateNew(weekInfo.end_date, "DD/MM/YYYY"), //format date
				confirm_status: confirmStatus,
			};
		} else if (type === "winner_list" && inputDate >= weekInfo.start_date && inputDate <= weekInfo.winner_end_date) {
			const currentDate = moment().format("YYYY/MM/DD HH:mm");
			let confirmStatus = "done";
			if (currentDate <= weekInfo.confirm_date) confirmStatus = "waiting";
			return {
				...weekInfo,
				start_date: campaign.convertDateNew(weekInfo.start_date, "DD/MM"), //format date
				end_date: campaign.convertDateNew(weekInfo.end_date, "DD/MM/YYYY"), //format date
				confirm_status: confirmStatus,
			};
		}
	}

	return null;
};

campaign.returnWeekInfo = (type, datas) => {
	if (Array.isArray(datas.record)) {
		datas.record = datas.record.map((data) => {
			const dataDate = campaign.convertDate(data.createdAt, "YYYY/MM/DD");
			const weekInfo = getWeekInfo(type, dataDate);

			return {
				...data,
				weekInfo: weekInfo,
			};
		});

		return datas;
	}
	return [];
};

campaign.isMobile = () => {
	const userAgent = window.navigator.userAgent || navigator.userAgent;
	const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
	return isMobile;
};

campaign.getPercentageData = (value, total) => {
	if (total === 0) return "0%";

	var percentageValue = (value / total) * 100;
	percentageValue = percentageValue.toFixed(0);

	return percentageValue + "%";
};

campaign.numberWithCommas = (value) => {
	try {
		return value.toLocaleString("vi-VN");
	} catch (error) {
		console.log("addDotsToNumber error :>> ", error);
		return value;
	}
};

export default campaign;
