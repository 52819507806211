import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useRecoilState, useRecoilValue } from "recoil";
import moment from "moment";
import _ from "lodash";

import constant from "../utils/constant";

import { userTestAtom } from "../stores/user_test";
import { adtimaAtom } from "../stores/adtima";

import LayoutStructure from "../components/LayoutStructure/LayoutStructure";
import LoadingPage from "../components/Loading/Loading";

const FullLayout = (props) => {
	let { title, description, bodyClass, children, isLoading } = props;

	const navigate = useNavigate();
	const location = useLocation();

	const [userTest] = useRecoilState(userTestAtom);
	const adtima = useRecoilValue(adtimaAtom);

	let search = location?.search;

	useEffect(() => {
		// Check go live time
		const listOpen = [constant.ROUTERS.COUNTDOWN, constant.ROUTERS.FOR_TEST];
		const date = moment().format("YYYY-MM-DD HH:mm");
		if (date < process.env.REACT_APP_CAMPAIGN_START_TIME) {
			if (_.isEmpty(userTest)) {
				if (listOpen.includes(location?.pathname)) {
				} else {
					// return navigate(constant.ROUTERS.COUNTDOWN + (search ? search : ""), { replace: true });
				}
			}
		}

		if (!adtima?.accessToken && !listOpen.includes(location?.pathname)) {
			return navigate(constant.ROUTERS.LUCKY_DRAW_LOGIN);
		}
	}, []);

	// useEffect(() => {
	// 	// Check go live time
	// 	if (!adtima?.accessToken) {
	// 		navigate(constant.ROUTERS.LUCKY_DRAW_LOGIN);
	// 	}
	// }, []);

	useEffect(() => {
		if (process.env.REACT_APP_ENV == "production") {
			// disable right-clicking anywhere on the page and selecting "Inspect" from the context menu.
			const handleContextMenu = (event) => {
				// console.log("right-clicking anywhere on the page and selecting Inspect");
				event.preventDefault();
				// if (event.target.tagName.toLowerCase() === "html") {
				// 	console.log("Inspect element triggered!");
				// 	event.preventDefault();
				// }
			};

			window.addEventListener("contextmenu", handleContextMenu);

			// disable press f12
			const handleKeyDown = (event) => {
				if (event.keyCode === 123) {
					// Check if F12 key is pressed
					// console.log("F12 key pressed!");
					event.preventDefault();
				}
			};

			window.addEventListener("keydown", handleKeyDown);
			return () => {
				window.removeEventListener("contextmenu", handleContextMenu);
				window.removeEventListener("keydown", handleKeyDown);
			};
		}
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{title ? `${title.toUpperCase()}` : process.env.REACT_APP_DEFAULT_TITLE}</title>

				<meta name="description" content={description || ""}></meta>

				<meta name="zalo-platform-site-verification" content="F-25DkZW2Hauw8WTn-KE12wRfp32vNnkEJ4" />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" viewport-fit="cover"></meta>

				<meta property="og:title" content={title ? `${title.toUpperCase()}` : ""} />
				<meta property="og:description" content={description || ""} />

				<meta property="og:image" content={""} />
				<meta property="og:image:alt" content={""} />
				<meta property="og:type" content="campaign" />
			</Helmet>
			<LoadingPage isLoading={isLoading} />
			<LayoutStructure bodyClass={bodyClass}>{children}</LayoutStructure>
		</>
	);
};
export default FullLayout;
