import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import Cookies from "js-cookie";
import { Container, Paper, TextField, Typography, Button } from "@mui/material";

import "./Login.scss";

import FullLayout from "../../layouts/LuckyDrawLayout";
import constant from "../../utils/constant";

import { userTestAtom } from "../../stores/user_test";

import AdtimaApi from "../../services/adtima-api";
const AdtimaApiService = new AdtimaApi();

import campaign from "../../utils/campaign";
import { adtimaAtom } from "../../stores/adtima";

function Login() {
	const navigate = useNavigate();

	const [adtima, setAdtima] = useRecoilState(adtimaAtom);
	
	const recaptchaRef = useRef();

	const [userId, setUserId] = useState(null);
	const [recaptchaValue, setRecaptchaValue] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [is2Auth, setIs2Auth] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const checkLuckyDrawInfo = async (token) => {
		try {
			const cpResult = await AdtimaApiService.getCPInfo(token);
			if (!cpResult?.round?.startDateDraw || !cpResult?.round?.endDateDraw) {
				setErrorMessage("Chưa cài đặt thời gian quay số may mắn.");
				return false;
			}

			const currentTime = campaign.currentDate("YYYY-MM-DD");
			const drawStartDate = campaign.convertDate(cpResult?.round?.startDateDraw, "YYYY-MM-DD");
			const drawEndDate = campaign.convertDate(cpResult?.round?.endDateDraw, "YYYY-MM-DD");
			if (currentTime < drawStartDate || currentTime > drawEndDate) {
				setErrorMessage("Ngày quay thưởng không hợp lệ.");
				return false;
			}

			return true;
		} catch (error) {
			console.log("error :>> ", error);
			return null;
		}
	};

	const saveAccessToken = (tokenUser) => {
		try {
			Cookies.set("adtimaInfo", JSON.stringify({ accessToken: tokenUser }), {
				expires: constant.COOKIE_EXPIRES_TIME,
			});
			setAdtima({ accessToken: tokenUser });

			navigate(constant.ROUTERS.LUCKY_DRAW);
		} catch (error) {
			console.log("error :>> ", error);
		}
	};

	const onSubmitLogin = async (data) => {
		try {
			const result = await AdtimaApiService.login({
				userName: data.username,
				password: data.password,
				captchaToken: recaptchaValue,
			});
			console.log("result :>> ", result);

			if (result?.statusCode === 200 && result?.data?.useTOTP === true && result?.data?.userId) {
				setUserId(result?.data?.userId);
				setErrorMessage(null);
				setIs2Auth(true);
			} else if (result?.statusCode === 200 && result?.data?.useTOTP === false && result?.data?.tokenUser?.data) {
				setErrorMessage(null);
				const isValidLuckyDraw = await checkLuckyDrawInfo(result?.data?.tokenUser?.data);
				if (!isValidLuckyDraw) return null;

				saveAccessToken(result?.data?.tokenUser?.data);
			} else {
				setErrorMessage(result?.message);
			}
			
			recaptchaRef.current.reset();
		} catch (error) {
			console.log("error :>> ", error);
		}
	};

	const onSubmit2Auth = async (data) => {
		try {
			const result = await AdtimaApiService.loginWith2Authen({
				userId: userId,
				passCode: data.passCode,
			});
			console.log("result :>> ", result);

			if (result?.statusCode === 200 && result?.data?.data) {
				setErrorMessage(null);
				const isValidLuckyDraw = await checkLuckyDrawInfo(result?.data?.data);
				if (!isValidLuckyDraw) return null;

				saveAccessToken(result?.data?.data);
			} else {
				setErrorMessage(result?.message);
			}
		} catch (error) {
			console.log("error :>> ", error);
		}
	};

	// useEffect(() => {
	// 	if (adtima?.accessToken) {
	// 		navigate(constant.ROUTERS.LUCKY_DRAW);
	// 	}
	// });

	return (
		<FullLayout bodyClass="pc" title="Trang đăng nhập quay số">
			<Container maxWidth="xs">
				<Paper elevation={3} style={{ padding: "20px" }}>
					<Typography variant="h5" className="mb-10" style={{ marginBottom: "15px" }}>
						Đăng Nhập
					</Typography>
					{!is2Auth && (
						<>
							<div style={{ marginBottom: "15px" }}>
								<TextField
									fullWidth
									type="text"
									label="Hộp thư *"
									{...register("username", {
										required: true,
										minLength: 3,
									})}
									minLength="3"
								/>
								{errors.username && <div className="error">Tên đăng nhập sai định dạng</div>}
							</div>
							<div style={{ marginBottom: "15px" }}>
								<TextField
									fullWidth
									type="password"
									{...register("password", {
										required: true,
										minLength: 5,
									})}
									label="Mật khẩu *"
									minLength="5"
								/>
								{errors.password && <div className="error">Mật khẩu sai định dạng</div>}
							</div>
							<div style={{ display: "flex", justifyContent: "center", marginBottom: "15px" }}>
								<ReCAPTCHA sitekey="6LdBcyslAAAAAJ5ouJ2lNumHGLXW4Kj9Bb0Et1TA" ref={recaptchaRef} onChange={(value) => setRecaptchaValue(value)} />
							</div>
							{errorMessage && (
								<Typography color="error" style={{ marginBottom: "15px" }}>
									{errorMessage}
								</Typography>
							)}
							<Button variant="contained" color="primary" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} onClick={handleSubmit(onSubmitLogin)}>
								Đăng nhập
							</Button>
						</>
					)}
					{is2Auth && (
						<>
							<div style={{ marginBottom: "15px" }}>
								<TextField
									fullWidth
									type="text"
									{...register("passCode", {
										required: true,
									})}
									label="Mã Google Authenticator *"
								/>
								{errors.passCode && <div className="error">Mã Google Authenticator sai định dạng</div>}
								{errorMessage && <div className="error">{errorMessage}</div>}
							</div>
							<Button variant="contained" color="primary" style={{ marginLeft: "auto", marginRight: "auto", display: "block" }} onClick={handleSubmit(onSubmit2Auth)}>
								Xác nhận
							</Button>
						</>
					)}
				</Paper>
			</Container>
		</FullLayout>
	);
}

export default Login;
