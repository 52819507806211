import React, { useState } from "react";
import Modal from "react-modal";
import { motion } from "framer-motion";

import "./Modals.scss";

import Logo from "../../components/Logo/Logo";

import logo from "../../assets/images/logo.png";
import icon from "../../assets/images/38.png";

const InvalidCodeModal = ({ modalIsOpen }) => {
	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				ariaHideApp={false}
				shouldCloseOnOverlayClick={false}
				shouldCloseOnEsc={false}
				contentLabel="Example Modal"
			>
				<div className="modal nexttime">
					<div className="md-content">
						<div className="logo-brand">
							<Logo logo={logo} />
						</div>
						<div className="body">
							<div className="wrap">
								<div className="icon">
									<div className="img">
										<img src={icon} />
									</div>
								</div>
								<p>
									Chúc bạn<br></br> may mắn lần sau.
								</p>
							</div>
						</div>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default InvalidCodeModal;
