import React from "react";
import { useNavigate } from "react-router-dom";
import "./Button.scss";
import { motion } from "framer-motion";

export default function Button({ handleOnClick, href, text, type, disable, align }) {
	const navigate = useNavigate();

	const toPath = () => {
		if (href) {
			setTimeout(() => {
				navigate(href);
			}, 200);
		} else if (handleOnClick) {
			handleOnClick();
		}
	};

	return (
		<motion.div whileTap={{ scale: 0.95 }} className={`button ${type} ${align} ${disable ? "disabled" : ""}`}>
			<div className={`txt`} onClick={toPath}>
				{text}
			</div>
		</motion.div>
	);
}
