import React from "react";

import "./InvalidCode.scss";

import FullLayout from "../../layouts/FullLayout";
import InvalidCodeModal from "../../components/Modals/InvalidCodeModal";

function InvalidCode() {
	return (
		<FullLayout bodyClass="invalid" title="Mã không hợp lệ">
			<InvalidCodeModal modalIsOpen={true}></InvalidCodeModal>
		</FullLayout>
	);
}

export default InvalidCode;
