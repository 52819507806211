import React, { useEffect, useState } from "react";
import { stagger, useAnimate } from "framer-motion";
import { useRecoilValue } from "recoil";

import "./LuckyDraw.scss";

import FullLayout from "../../layouts/LuckyDrawLayout";

import p1 from "../../assets/images/p1.png";
import p2 from "../../assets/images/p2_2.png";
import p3 from "../../assets/images/p3_2.png";
import product from "../../assets/images/product_2.png";
import title from "../../assets/images/title2.png";
import gift from "../../assets/images/59.png";
import Button from "../../components/Button/Button";

import { adtimaAtom } from "../../stores/adtima";

import campaign from "../../utils/campaign";
import AdtimaApi from "../../services/adtima-api";
const AdtimaApiService = new AdtimaApi();

function LuckyDraw() {
	const [scope, animate] = useAnimate();

	const adtima = useRecoilValue(adtimaAtom);

	const [errorMessage, setErrorMessage] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [winners, setWinners] = useState([]);
	const [roundInfo, setRoundInfo] = useState(null);
	const [luckyCode, setLuckyCode] = useState(null);
	const [isShowButton, setIsShowButton] = useState(true);
	const [countdown, setCountdown] = useState(0);

	const getWinners = async (accessToken, roundId) => {
		try {
			const winnersResult = await AdtimaApiService.getWinners(accessToken, roundId);
			if (typeof winnersResult === "string") {
				setIsShowButton(false);
				return setErrorMessage(winnersResult);
			}

			if (winnersResult && winnersResult?.length >= 2) setIsShowButton(false);
			setWinners(winnersResult);

			return winnersResult;
		} catch (error) {
			console.log("error :>> ", error);
			return null;
		}
	};

	const startCountdownDraw = (startTime) => {
		const intervalDraw = setInterval(() => {
			const currentTime = campaign.currentDate();
			if (currentTime >= startTime) {
				setIsShowButton(true);
				setErrorMessage(null);
				clearInterval(intervalDraw);
				return 0;
			}
		}, 1000);
	};

	const startCountdown = () => {
		const interval = setInterval(() => {
			setCountdown((prevCountdown) => {
				if (prevCountdown === 0) {
					setIsShowButton(true);
					clearInterval(interval);
					return 0;
				}
				return prevCountdown - 1;
			});
		}, 1000);
	};

	const handleClickStarSpinning = async () => {
		if (isLoading) return;
		try {
			setIsLoading(true);
			const spinningResult = await AdtimaApiService.spinningLuckyUser(adtima?.accessToken);
			console.log("spinningResult :>> ", spinningResult);
			if (spinningResult?.statusCode != 200 || !spinningResult?.data) {
				setIsShowButton(false);
				return setErrorMessage(spinningResult?.message);
			}

			setLuckyCode(spinningResult?.data?.code);

			const winnersResult = await getWinners(adtima?.accessToken, roundInfo?._id);
			if (winnersResult?.length >= 2) return;

			setIsShowButton(false);

			setCountdown(60); // Đặt lại countdown về 60 khi click vào button
			startCountdown();

			return winnersResult;
		} catch (error) {
			console.log("error :>> ", error);
		} finally {
			setIsLoading(false);
			setTimeout(() => {
				setLuckyCode(null);
			}, 3000);
		}
	};

	useEffect(() => {
		const initData = async () => {
			try {
				let resultCP = await AdtimaApiService.getCPInfo(adtima?.accessToken);
				console.log("resultCP :>> ", resultCP);
				if (!resultCP) throw new Error("Không hợp lệ");

				const currentTime = campaign.currentDate();
				const drawStartDate = campaign.convertDate(resultCP?.round?.startDateDraw);
				const drawEndDate = campaign.convertDate(resultCP?.round?.endDateDraw);
				if (currentTime < drawStartDate || currentTime > drawEndDate) {
					setErrorMessage(
						"Thời gian quay số may mắn sẽ bắt đầu từ " +
							campaign.convertDate(resultCP?.round?.startDateDraw, "DD/MM/YYYY HH:mm:ss") +
							" đến " +
							campaign.convertDate(resultCP?.round?.endDateDraw, "DD/MM/YYYY HH:mm:ss")
					);
					setIsShowButton(false);
					startCountdownDraw(drawStartDate);
				}

				setRoundInfo({
					_id: resultCP?.round?._id,
					name: resultCP?.round?.name,
					startDate: campaign.convertDate(resultCP?.round?.startDate, "DD/MM/YYYY HH:mm:ss"),
					endDate: campaign.convertDate(resultCP?.round?.endDate, "DD/MM/YYYY HH:mm:ss"),
				});

				const winnersResult = await getWinners(adtima?.accessToken, resultCP?.round?._id);
				return winnersResult;
			} catch (error) {
				console.log("error :>> ", error);
			}
		};

		initData();

		animate(
			".animUp",
			{
				y: [20, 0],
				opacity: [0, 1],
			},
			{
				type: "spring",
				delay: stagger(0.1),
			}
		);
	}, []);

	return (
		<FullLayout bodyClass="pc" title="Trang quay số" isLoading={isLoading}>
			<div className="page" ref={scope}>
				<div className="bl bl-left">
					<div className="animUp title">
						<div className="img">
							<img src={title} />
						</div>
					</div>
					<div className="animUp product mb-20">
						<div className="wrap">
							<div className="main">
								<div className="img">
									<img src={product} />
								</div>
							</div>
							<div className="prize p1">
								<div className="img animScale">
									<img src={p1} />
								</div>
							</div>
							<div className="prize p2">
								<div className="img animScale">
									<img src={p2} />
								</div>
							</div>
							<div className="prize p3">
								<div className="img animScale">
									<img src={p3} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="bl bl-right">
					<div className="head mb-30">
						<div className="title animUp">QUAY SỐ MAY MẮN</div>
					</div>
					<div className="body">
						{luckyCode && (
							<div className="code mb-40 animUp">
								<div className="wrap">
									<ul>
										<li>{luckyCode}</li>
									</ul>
								</div>
							</div>
						)}
						{!luckyCode && (
							<div className="gift animUp mb-40">
								<div className="img">
									<img src={gift} />
								</div>
							</div>
						)}
						<div className="winner animUp">
							{winners?.length > 0 && <div className="text mb-40 animUp">Thông tin người trúng giải {roundInfo?.name}</div>}
							{winners?.map((winner) => (
								<div className="information" style={{ marginBottom: "15px" }} key={winner._id}>
									<div className="item">
										Tên tài khoản: <span>{winner?.campaign_user_id?.name}</span>
									</div>
									<div className="item">
										Số điện thoại: <span>{winner?.campaign_user_id?.phone}</span>
									</div>
								</div>
							))}
						</div>
						{isShowButton && (
							<div className="animUp mb-20">
								<Button handleOnClick={handleClickStarSpinning} text="BẮT ĐẦU QUAY" align="center" />
							</div>
						)}
						{!isShowButton && errorMessage && (
							<div className="error mb-20 animUp" style={{ textAlign: "center" }}>
								{errorMessage}
							</div>
						)}
						{!isShowButton && countdown > 0 && (
							<div className="lds-spinner animUp mb-30">
								<div className="lds-roller">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
								<div>Lượt quay tiếp theo sẽ bắt đầu sau {countdown} giây</div>
							</div>
						)}
						<div className="date animUp mb-30">
							Tất cả các mã dự thưởng<br></br> từ ngày <span>{roundInfo?.startDate}</span> đến <span>{roundInfo?.endDate}</span>
						</div>
					</div>
				</div>
			</div>
		</FullLayout>
	);
}

export default LuckyDraw;
