import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import "./Countdown.scss";

import FullLayout from "../../layouts/FullLayout";
import LuckyDrawLayout from "../../layouts/LuckyDrawLayout";

import title from "../../assets/images/title.png";
import product from "../../assets/images/product_2.png";
import p1 from "../../assets/images/p1.png";
import p2 from "../../assets/images/p2_2.png";
import p3 from "../../assets/images/p3_2.png";
import reject from "../../assets/images/32_3.png";

import constant from "../../utils/constant";
import { stagger, useAnimate } from "framer-motion";

const OpenZalo = () => {
	const navigate = useNavigate();
	const location = useLocation();

	let search = location?.search;
	let searchParams = new URLSearchParams(search);
	const code = searchParams.get("c") || searchParams.get("C");

	const [scope, animate] = useAnimate();

	const userAgent = window.navigator.userAgent || navigator.userAgent;

	const [isLoadMobile, setIsLoadMobile] = useState(false);

	const calculateTimeLeft = () => {
		const difference = new Date(process.env.REACT_APP_CAMPAIGN_START_TIME) - new Date();
		let countdownTime = {};

		if (difference > 0) {
			countdownTime = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		} else {
			countdownTime = {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
			};
		}

		return countdownTime;
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
		setIsLoadMobile(isMobile);

		const timer = setInterval(() => {
			let currentTime = calculateTimeLeft();
			setTimeLeft(currentTime);

			if (currentTime.days === 0 && currentTime.hours === 0 && currentTime.minutes === 0 && currentTime.seconds === 0) {
				clearInterval(timer);

				if (code) navigate(constant.ROUTERS.QR + location.search);
				else navigate(constant.ROUTERS.HOME + location.search);
			}
		}, 1000);

		// animate(
		// 	".animUp",
		// 	{
		// 		y: [20, 0],
		// 		opacity: [0, 1],
		// 	},
		// 	{
		// 		type: "spring",
		// 		delay: stagger(0.1),
		// 	}
		// );
	}, []);

	return (
		<>
			{isLoadMobile ? (
				<LuckyDrawLayout bodyClass="reject noti" title="Trang chủ">
					<div className="page" ref={scope}>
						<div className="content-w size-md">
							<div className="wrap mh">
								<div className="p1">
									<div className="animUp bl-title">
										<div className="img">
											<img src={title} />
										</div>
									</div>
									<div className="animUp product">
										<div className="wrap">
											<div className="main">
												<div className="img">
													<img src={product} />
												</div>
											</div>
											<div className="prize p1">
												<div className="img">
													<img src={p1} />
												</div>
											</div>
											<div className="prize p2">
												<div className="img">
													<img src={p2} />
												</div>
											</div>
											<div className="prize p3">
												<div className="img">
													<img src={p3} />
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="noti mt-20 animUp ">
									<div className="ic">
										<div className="img">
											<img src={reject} />
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</LuckyDrawLayout>
			) : (
				<FullLayout bodyClass="event-time" title="Countdown">
					<div id="countdown">
						<ul>
							<li>
								<span id="days">{timeLeft.days}</span>NGÀY
							</li>
							<li>
								<span id="hours">{timeLeft.hours}</span>GIỜ
							</li>
							<li>
								<span id="minutes">{timeLeft.minutes}</span>PHÚT
							</li>
							<li>
								<span id="seconds">{timeLeft.seconds}</span>GIÂY
							</li>
						</ul>
					</div>
				</FullLayout>
			)}
		</>
	);
};

export default OpenZalo;
