import React, { useEffect } from "react";
import _ from "lodash";

import FullLayout from "../../layouts/FullLayout";

import "./Home.scss";

function Authorize() {

	useEffect(() => {
		window.location.href = `https://oauth.zaloapp.com/v4/permission?app_id=2156689631436515301&redirect_uri=https://rockstarkhuyenmai.vn&state=rockstarkhuyenmai.vn`;
	}, []);

	return <FullLayout isLoading={true} title="Trang chủ"></FullLayout>;
}

export default Authorize;
