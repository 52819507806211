import axios from "axios";

export const http_request = (params) => {
	return new Promise(function (resolve, reject) {
		if (!params.url) reject(false);
		let headers = {
			Accept: "application/json",
			"Content-Type": "application/json; charset=utf-8",
		};
		if (typeof params.headers === "object") {
			headers = Object.assign(headers, params.headers);
		}
		const axiosOptions = {
			baseURL: params.baseURL || "",
			headers: headers,
			httpsAgent: undefined,
		};
		const axiosClient = axios.create(axiosOptions);
		if (params.method == "GET") {
			axiosClient
				.get(params.url, params.data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					console.log("err", err);
					reject(null);
				});
		} else if (params.method == "POST") {
			axiosClient
				.post(params.url, params.data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					console.log("err :>> ", err);
					reject(err.response.data);
				});
		} else if (params.method == "PUT") {
			axiosClient
				.put(params.url, params.data)
				.then((res) => {
					resolve(res.data);
				})
				.catch((err) => {
					console.log("err", err);
					reject(null);
				});
		} else {
			reject(null);
		}
	});
};
