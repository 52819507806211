// import React, { useEffect, useState, useRef } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import constant from "../src/utils/constant";

import Countdown from "./pages/Countdown/Countdown";
import QR from "./pages/QR/QR";
import InvalidCode from "./pages/InvalidCode/InvalidCode";
import FOR_TEST from "./pages/Test/Test";

import LuckyDraw from "./pages/LuckyDraw/LuckyDraw";
import Login from "./pages/Login/Login";
import Home from "./pages/Home/Home";
import QRTest from "./pages/QR/QRTest";
import Authorize from "./pages/Home/Authorize";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={constant.ROUTERS.COUNTDOWN} element={<Countdown />} />
				<Route path={constant.ROUTERS.QR} element={<QR />} />
				<Route path={constant.ROUTERS.QR_TEST} element={<QRTest />} />
				<Route path={constant.ROUTERS.INVALID_CODE} element={<InvalidCode />} />
				<Route path={constant.ROUTERS.FOR_TEST} element={<FOR_TEST />} />
				<Route path={constant.ROUTERS.AUTHORIZE} element={<Authorize />} />

				<Route path={constant.ROUTERS.LUCKY_DRAW} element={<LuckyDraw />} />
				<Route path={constant.ROUTERS.LUCKY_DRAW_LOGIN} element={<Login />} />
				<Route index element={<Home />} />

				<Route path="*" element={<Navigate replace to={constant.ROUTERS.HOME} />} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
