import React from "react";

import value1 from "../../assets/images/51.png";
import value2 from "../../assets/images/52.png";
import value3 from "../../assets/images/53.png";
import label_g1 from "../../assets/images/50.png";
import label_g2 from "../../assets/images/47.png";
import label_g3 from "../../assets/images/48.png";
import bar1 from "../../assets/images/54.png";
import bar2 from "../../assets/images/54_3.png";

export default function GiftQuota({ styles1, styles2, styles3 }) {
	return (
		<div className="prize-status mb-20 animUp">
			<div className="list ">
				{styles1 && (
					<div className="item mb-16 animScale">
						<label>{styles1?.assignedQuantity} giải đã có chủ nhân</label>
						<div className="progress mb-8">
							<div className="bar">
								<div className="img" style={styles1?.style}>
									<img src={bar1} />
								</div>
							</div>
							<div className="value ">
								<div className="img">
									<img src={value2} />
								</div>
							</div>
						</div>
						<div className="label">
							<div className="img">
								<img src={label_g1} />
							</div>
						</div>
					</div>
				)}
				{styles2 && (
					<div className="item mb-16 animScale">
						<label>{styles2?.assignedQuantity} giải đã có chủ nhân</label>
						<div className="progress mb-8">
							<div className="bar">
								<div className="img" style={styles2?.style}>
									<img src={bar1} />
								</div>
							</div>
							<div className="value">
								<div className="img">
									<img src={value1} />
								</div>
							</div>
						</div>
						<div className="label">
							<div className="img">
								<img src={label_g2} />
							</div>
						</div>
					</div>
				)}
				{styles3 && (
					<div className="item animScale">
						<label>{styles3?.assignedQuantity} giải đã có chủ nhân</label>
						<div className="progress mb-8">
							<div className="bar">
								<div className="img" style={styles3?.style}>
									<img src={bar2} />
								</div>
							</div>
							<div className="value">
								<div className="img">
									<img src={value3} />
								</div>
							</div>
						</div>
						<div className="label">
							<div className="img">
								<img src={label_g3} />
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
