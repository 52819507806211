import ReactGA from "react-ga4";

// log the pageview with their URL
export const initialize = () => {
	ReactGA.initialize([
		{
			trackingId: "G-8PT8FLVMGZ",
			testMode: false,
		},
	]);
};

export const pageview = (url, title = "Custom Title") => {
	// console.log(url, title)
	let objectView = { hitType: "pageview", page: url, title: title };
	ReactGA.send(objectView);
};

// log specific events happening.
export const trackEvent = (name, params) => {
	ReactGA.event(name, params);
};
