import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useRecoilState } from "recoil";
import moment from "moment";
import _ from "lodash";

import constant from "../utils/constant";
import * as ga4 from "../libs/ga/ga4";
import * as ga4Pepsi from "../libs/ga/ga4-pepsi";

import { userTestAtom } from "../stores/user_test";

import LoadingPage from "../components/Loading/Loading";

const FullLayout = (props) => {
	let { title, description, bodyClass, children, isLoading } = props;

	const navigate = useNavigate();
	const location = useLocation();

	const [userTest] = useRecoilState(userTestAtom);

	let search = location?.search;
	const domainName = window.location.origin;

	useEffect(() => {
		// Check go live time
		const listOpen = [constant.ROUTERS.COUNTDOWN, constant.ROUTERS.FOR_TEST, constant.ROUTERS.QR_TEST];
		const date = moment().format("YYYY-MM-DD HH:mm");
		if (date < process.env.REACT_APP_CAMPAIGN_START_TIME) {
			if (_.isEmpty(userTest)) {
				if (listOpen.includes(location?.pathname)) {
				} else {
					return navigate(constant.ROUTERS.COUNTDOWN + (search ? search : ""), { replace: true });
				}
			}
		}

		// if (domainName.indexOf("rskm.vn") >= 0) ga4.pageview(location?.pathname + location?.search, title);
		// else ga4Pepsi.pageview(location?.pathname + location?.search, title);
	}, []);

	useEffect(() => {
		if (process.env.REACT_APP_ENV == "production") {
			// disable right-clicking anywhere on the page and selecting "Inspect" from the context menu.
			const handleContextMenu = (event) => {
				// console.log("right-clicking anywhere on the page and selecting Inspect");
				event.preventDefault();
				// if (event.target.tagName.toLowerCase() === "html") {
				// 	console.log("Inspect element triggered!");
				// 	event.preventDefault();
				// }
			};

			window.addEventListener("contextmenu", handleContextMenu);

			// disable press f12
			const handleKeyDown = (event) => {
				if (event.keyCode === 123) {
					// Check if F12 key is pressed
					// console.log("F12 key pressed!");
					event.preventDefault();
				}
			};

			window.addEventListener("keydown", handleKeyDown);
			return () => {
				window.removeEventListener("contextmenu", handleContextMenu);
				window.removeEventListener("keydown", handleKeyDown);
			};
		}
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>{title ? `${title}` : process.env.REACT_APP_DEFAULT_TITLE}</title>

				<meta name="description" content={description || ""}></meta>

				<meta name="zalo-platform-site-verification" content="F-25DkZW2Hauw8WTn-KE12wRfp32vNnkEJ4" />
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0" viewport-fit="cover"></meta>

				<meta property="og:title" content={title ? `${title}` : ""} />
				<meta property="og:description" content={description || ""} />

				<meta property="og:image" content={""} />
				<meta property="og:image:alt" content={""} />
				<meta property="og:type" content="campaign" />
			</Helmet>
			<LoadingPage isLoading={isLoading} />
			{children}
		</>
	);
};
export default FullLayout;
