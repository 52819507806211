import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import { useRecoilValue } from "recoil";

import "./QR.scss";

import FullLayout from "../../layouts/FullLayout";
import constant from "../../utils/constant";

import { userTestAtom } from "../../stores/user_test";

function QR() {
	const navigate = useNavigate();
	const location = useLocation();

	const userTest = useRecoilValue(userTestAtom);

	let search = location?.search;
	let searchParams = new URLSearchParams(search);
	const code = searchParams.get("c") || searchParams.get("C");

	useEffect(() => {
		const date = moment().format("YYYY-MM-DD HH:mm");
		if (date >= process.env.REACT_APP_CAMPAIGN_START_TIME || !_.isEmpty(userTest)) {
			if (code && /^[a-zA-Z0-9]{8}$/.test(code)) window.location.href = process.env.REACT_APP_MINI_APP_URL + location.search;
			else navigate(constant.ROUTERS.INVALID_CODE);
		}
	}, []);

	return (
		<FullLayout bodyClass="welcome" isLoading={true} title="Quét QR">
			{/* Loading... */}
		</FullLayout>
	);
}

export default QR;
