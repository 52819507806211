import React from "react";

import "./Loading.scss";

export default function Loading({ isLoading }) {
	return (
		<div className={`loading ${isLoading ? "" : "is-hide"}`} style={{ backgroundColor: "#000000" }}>
			<div className="loading-wrap">
				<div className="load-wrapp">
					<div className="load-6">
						<div className="letter-holder" style={{ padding: "40px" }}>
							<div className="l-1 letter">Đ</div>
							<div className="l-2 letter">a</div>
							<div className="l-3 letter">n</div>
							<div className="l-4 letter">g</div>
							<div className="l-5 letter">&nbsp;</div>
							<div className="l-5 letter">t</div>
							<div className="l-6 letter">ả</div>
							<div className="l-7 letter">i</div>
							<div className="l-8 letter">.</div>
							<div className="l-9 letter">.</div>
							<div className="l-10 letter">.</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
