import React from "react";

import tnc1 from "../../assets/images/tnc1.jpg";
import tnc2 from "../../assets/images/tnc2.jpg";
import tnc3 from "../../assets/images/tnc3.jpg";
import tnc4 from "../../assets/images/tnc4.jpg";
import tnc5 from "../../assets/images/tnc5.jpg";
import tnc6 from "../../assets/images/tnc6.jpg";
import tnc8 from "../../assets/images/tnc8.jpg";
import tnc9 from "../../assets/images/tnc9.jpg";
import tnc10 from "../../assets/images/tnc10.jpg";
import tnc11 from "../../assets/images/tnc11.jpg";
import tnc12 from "../../assets/images/tnc12.jpg";
import tnc13 from "../../assets/images/tnc13.jpg";
import tnc13_1 from "../../assets/images/tnc13_1.jpg";
import tnc13_2 from "../../assets/images/tnc13_2.jpg";
import tagline from "../../assets/images/56.png";
import tnc14 from "../../assets/images/tnc14.jpg";
import tnc15 from "../../assets/images/tnc15.jpg";

export default function Tagline({}) {
	return (
		<div className="content">
			<div className="content-wrap">
				<div className="bl">
					<h4>1. Tên chương trình khuyến mại</h4>
					<div className="name">
						<div className="img">
							<img src={tagline} />
						</div>
					</div>
				</div>
				<div className="bl">
					<h4>2. Hàng hoá, dịch vụ khuyến mại: </h4>
					<p>
						Sản phẩm Nước tăng lực Rockstar lon 250ml có in thông tin về chương trình khuyến mại trên nhãn (Sau đây gọi tắt là <strong>“Sản Phẩm Khuyến Mại”</strong>)
					</p>
				</div>
				<div className="bl">
					<h4>3. Thời gian khuyến mại: </h4>
					<p>Từ 00 giờ 00 phút ngày 01/07/2024 đến hết 17 giờ 00 phút ngày 31/08/2024.</p>
				</div>
				<div className="bl">
					<h4>4. Địa bàn (phạm vi) khuyến mại: </h4>
					<p>Toàn quốc</p>
				</div>
				<div className="bl">
					<h4>5. Hình thức khuyến mại:</h4>
					<p>Chương trình khuyến mại mang tính may rủi</p>
				</div>
				<div className="bl">
					<h4>6. Đối tượng hưởng khuyến mại:</h4>
					<p>
						Khách hàng là tất cả người tiêu dùng (là công dân nước Cộng hòa xã hội chủ nghĩa Việt Nam) từ đủ 18 tuổi có tài khoản ứng dụng Zalo tương ứng với số điện
						thoại tham gia chương trình đang hoạt động mua Sản Phẩm Khuyến Mại tại địa bàn khuyến mại trong thời gian diễn ra chương trình.{" "}
					</p>
					<p>
						<strong>Lưu ý:</strong>
					</p>
					<p>- Chương trình chỉ áp dụng cho người tiêu dùng là công dân nước Cộng hòa xã hội chủ nghĩa Việt Nam. </p>
					<p>
						- Nhân viên của SPVB và nhân viên của các đại lý, nhà phân phối, nhà quảng cáo, nhà cung cấp chịu trách nhiệm in ấn, vận chuyển, sản xuất Sản Phẩm Khuyến
						Mại, hoặc tham gia tư vấn, kiểm toán, kiểm soát hoặc phát triển phần mềm, hoạt động kỹ thuật phục vụ chương trình khuyến mại không được tham gia chương
						trình này.
					</p>
					<h4>7. Cơ cấu giải thưởng:</h4>
					<div className="table">
						<table>
							<thead>
								<tr>
									<td>Cơ cấu giải thưởng </td>
									<td>Nội dung giải thưởng </td>
									<td>Trị giá giải thưởng tương đương (đồng) </td>
									<td>Số lượng (giải) </td>
									<td>Thành tiền (đồng)</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Giải may mắn 1</td>
									<td>Lượt nạp tiền điện thoại trị giá 10.000 đồng/lượt</td>
									<td>10.000</td>
									<td>600.000</td>
									<td>6.000.000.000</td>
								</tr>
								<tr>
									<td>Giải may mắn 2</td>
									<td>Lượt nạp tiền điện thoại trị giá 20.000 đồng/lượt</td>
									<td>20.000</td>
									<td>400.000</td>
									<td>8.000.000.000</td>
								</tr>
								<tr>
									<td>Giải Đặc Biệt</td>
									<td>100.000.000 đồng tiền mặt</td>
									<td>100.000.000</td>
									<td>10</td>
									<td>1.000.000.000</td>
								</tr>
								<tr>
									<td></td>
									<td></td>
									<td>
										<strong>Tổng cộng:</strong>
									</td>
									<td>
										<strong>1.000.010</strong>
									</td>
									<td>
										<strong>15.000.000.000</strong>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p>
						- Tổng giá trị giải thưởng khuyến mại: 15.000.000.000 đồng (Mười lăm tỷ đồng). Tổng số lượng giải thưởng khuyến mại là số lượng giải thưởng mà SPVB có nghĩa
						vụ phải trao giải trong chương trình này.
					</p>
					<p>- Giải thưởng là Lượt nạp tiền điện thoại không có giá trị quy đổi thành tiền mặt.</p>
					<p>- Tổng giá trị giải thưởng so với tổng giá trị hàng hoá khuyến mại: khoảng 5,6%</p>
				</div>
				<div className="bl">
					<h4> 8. Nội dung chi tiết thể lệ chương trình khuyến mại:</h4>
					<p>
						<strong>8.1. Điều kiện, cách thức, thủ tục cụ thể khách hàng phải thực hiện để được tham gia Chương trình khuyến mại: </strong>
					</p>
					<p className="cap">
						<strong>Bước 1: </strong>
					</p>
					<p>
						Khách hàng mua Sản Phẩm khuyến Mại có thông tin về chương trình khuyến mại trên nhãn sản phẩm, sau đó tìm Mã QR in bên dưới nắp khoen Sản Phẩm Khuyến Mại và
						tham gia chương trình bằng cách mở ứng dụng Zalo và chọn tính năng quét QR của ứng dụng để quét Mã QR in bên dưới nắp khoen lon Sản Phẩm Khuyến Mại để dẫn
						đến trang thông tin tham gia chương trình khuyến mại tại địa chỉ https://rockstarkhuyenmai.vn (sau đây gọi là "Website"){" "}
					</p>
					<p>
						{" "}
						<strong>Lưu ý</strong>: Công ty TNHH Nước giải khát Suntory PepsiCo Việt Nam sẽ liên hệ và trao thưởng, nạp tiền điện thoại vào số điện thoại của tài khoản
						Zalo mà khách hàng tham gia chương trình. Do vậy, khách hàng cần lưu ý và sử dụng đúng số điện thoại để tham gia và nhận thưởng.{" "}
					</p>
					<div className="img">
						<img src={tnc1} />
					</div>
					<div className="img">
						<img src={tnc2} />
					</div>
					<p>
						<strong>Bước 2:</strong>{" "}
					</p>
					<p>
						Tại giao diện Website, khách hàng nhấn xác nhận đồng thời 04 nội dung: “Tôi đã đọc và đồng ý với thể lệ và điều khoản của chương trình”, “Tôi đã đọc, hiểu
						và đồng ý với Chính sách về quyền riêng tư của SPVB”, “Tôi xác nhận đủ hoặc trên 18 tuổi”, “Quan tâm Zalo OA để nhận thông báo giải thưởng”. Sau khi xác
						nhận đủ 04 nội dung trên, khách hàng nhấn vào nút “THAM GIA NGAY”. Khách hàng lần lượt thực hiện nhấn vào biểu tượng “Quan tâm” Nước tăng lực Rockstar để
						nhãn hàng Nước tăng lực Rockstar của Công ty TNHH Nước giải khát Suntory PepsiCo Việt Nam có thể liên hệ với quý khách hàng thông qua tài khoản Zalo của quý
						khách nhằm thông báo trúng thưởng hoặc cập nhật các thông tin về chương trình này và chọn “Cho phép” chương trình nhận thông tin sử dụng tài khoản Zalo để
						tham gia chương trình. Trên giao diện của Website, khách hàng nhập mã bảo mật (hiển thị trên màn hình) và bấm vào nút “NHẬN THƯỞNG NGAY” để chờ hệ thống xử
						lý kết quả. Đối với khách hàng tham gia chương trình từ lần thứ hai trở đi, sau khi khách hàng quét mã QR theo hướng dẫn ở Bước 1, giao diện màn hình
						Website ở Bước 2 sẽ trực tiếp hiển thị ô nhập mã bảo mật. Khách hàng nhập mã bảo mật (hiển thị bên trên) theo hướng dẫn tại giao diện Website và nhấn nút
						“NHẬN THƯỞNG NGAY” để chờ hệ thống xử lý kết quả.
					</p>
					<p>
						<strong>Bước 2: Giao diện khi khách hàng tham gia lần đầu tiên</strong>{" "}
					</p>
					<div className="img">
						<img src={tnc5} />
					</div>
					<div className="img">
						<img src={tnc4} />
					</div>
					<div className="img">
						<img src={tnc3} />
					</div>
					<div className="img">
						<img src={tnc6} />
					</div>

					<p className="cap">
						<strong>Bước 2: Giao diện khi khách hàng tham gia từ lần thứ 2 trở đi</strong>{" "}
					</p>
					<div className="img">
						<img src={tnc6} />
					</div>
					<p>
						<strong>Bước 3:</strong>
					</p>
					<p>
						Trong vòng tối đa 12 giờ kể từ khi khách hàng nhấn nút “NHẬN THƯỞNG NGAY” (riêng vào ngày cuối cùng của chương trình khuyến mại, hệ thống sẽ xử lý trong
						vòng 30 phút kể từ khi khách hàng nhấn nút “NHẬN THƯỞNG NGAY”), Hệ Thống Phần Mềm tự động không có sự can thiệp của con người (sau đây gọi tắt là “Hệ Thống
						Phần Mềm’’) sẽ gửi thông báo đến khách hàng từ tài khoản Zalo Nước tăng lực Rockstar với nội dung cụ thể như sau:
					</p>
					<p>- Đối với Giải may mắn 1: Khi quét thành công 01 mã QR code </p>
					<p>
						* Trường hợp trúng Giải may mắn 1 và trúng ngôi sao may mắn: Khách hàng sẽ nhận được thông báo với nội dung như sau: "Chúc mừng bạn trúng giải nạp tiền điện
						thoại 10.000 VNĐ và nhận được 1 ngôi sao may mắn!!! Tiếp tục quét Mã QR và sưu tầm đủ 6 ngôi sao may mắn để được nạp tiền điện thoại 20.000 VNĐ nhé!".{" "}
					</p>
					<p>Hình ảnh giao diện màn hình:</p>
					<p>Nếu người tham gia đã cho phép truy cập số điện thoại:</p>
					<div className="img">
						<img src={tnc8} />
					</div>
					<p>
						Nếu người tham gia chưa cho phép truy cập số điện thoại, thông tin trúng giải sẽ hiện ra trong 1 giây và chuyển đến phần yêu cầu cho phép truy cập số điện
						thoại:
					</p>
					<div className="img">
						<img src={tnc8} />
					</div>
					<div className="img">
						<img src={tnc15} />
					</div>
					<p>* Trường hợp không trúng Giải may mắn 1 nhưng trúng ngôi sao may mắn: Khách hàng sẽ nhận được thông báo trực tiếp tại giao diện Website.</p>
					<p>Hình ảnh giao diện màn hình:</p>
					<div className="img">
						<img src={tnc9} />
					</div>
					<p>* Trường hợp không trúng Giải may mắn 1 và cũng không trúng ngôi sao may mắn: Khách hàng sẽ nhận được thông báo trực tiếp tại giao diện Website.</p>
					<p>Hình ảnh giao diện màn hình:</p>
					<div className="img">
						<img src={tnc10} />
					</div>
					<p>
						- Đối với Giải may mắn 2 và Giải Đặc Biệt: Khi sưu tầm đủ 6 ngôi sao may mắn, khách hàng sẽ luôn nhận được một Mã Dự thưởng để tham gia chương trình quay số
						may mắn với cơ hội trúng Giải Đặc Biệt. Đồng thời, khách hàng sẽ cơ cơ hội nhận được Giải may mắn 2
					</p>
					<p>* Trường hợp trúng giải may mắn 2: Khách hàng sẽ nhận được thông báo với nội dung như sau: </p>
					<p>
						"Chúc mừng bạn đã sưu tầm đủ 6 ngôi sao may mắn và trúng giải nạp tiền điện thoại trị giá 20.000 VNĐ!!! Đồng thời, bạn đã nhận được mã dự thưởng XXXXXXXX để
						tham gia chương trình quay số may mắn trúng 100.000.000 VNĐ tiền mặt
					</p>
					<p>Tiếp tục quét Mã QR và sưu tầm đủ 6 ngôi sao may mắn mới để được nạp thêm tiền điện thoại 20.000 VNĐ nhé!”</p>
					<p>Hình ảnh giao diện màn hình:</p>
					<p>Nếu người tham gia đã cho phép truy cập điện thoại:</p>
					<div className="img">
						<img src={tnc13_1} />
					</div>
					<p>
						Nếu người tham gia chưa cho phép truy cập số điện thoại, thông tin trúng giải sẽ hiện ra trong 1 giây và chuyển đến phần yêu cầu cho phép truy cập số điện
						thoại:
					</p>
					<div className="img">
						<img src={tnc13} />
					</div>
					<div className="img">
						<img src={tnc15} />
					</div>
					<p>* Trường hợp không trúng Giải may mắn 2: Khách hàng sẽ nhận được thông báo với nội dung như sau: </p>
					<p>
						"Chúc mừng bạn đã sưu tầm đủ 6 ngôi sao may mắn và nhận được mã dự thưởng XXXXXXXX để tham gia chương trình quay số may mắn trúng 100.000.000 VNĐ tiền
						mặt!!! Tiếp tục quét Mã QR và sưu tầm đủ 6 ngôi sao may mắn mới để có thêm nhiều mã dự thưởng nhé!”
					</p>
					<p>Hình ảnh giao diện màn hình có ngôi sao may mắn: </p>
					<div className="img">
						<img src={tnc13_2} />
					</div>
					<p>
						* Nếu khách hàng quét Mã QR trước thời gian diễn ra chương trình khuyến mại (trước 00 giờ 00 phút ngày 01/07/2024), Khách hàng sẽ nhận được thông báo với
						nội dung như sau: “Chương trình chưa bắt đầu. GIỮ NẮP KHOEN và quét lại Mã QR từ ngày 01/07/2024 để có cơ hội trúng các giải thưởng từ chương trình’’
					</p>
					<p>
						* Thời hạn cuối cùng để khách hàng quét QR là 17 giờ 30 phút ngày 31/08/2024. Nếu khách hàng quét Mã QR sau thời gian này, Khách hàng sẽ nhận được thông báo
						với nội dung như sau: “Chương trình đổi thưởng đã hết hạn từ 17 giờ 30 phút ngày 31/08/2024. Cảm ơn bạn đã ủng hộ Rockstar”.
					</p>
					<p>
						* Nếu khách hàng quét cùng 01 Mã QR nhiều lần, khách hàng quét từ lần thứ 02 trở đi sẽ nhận được thông báo trực tiếp tại giao diện Website với nội dung:
						“Chúc bạn may mắn lần sau” (Không có tin nhắn từ tài khoản Zalo “Nước tăng lực Rockstar”, chỉ có thông báo trên giao diện website – xem ảnh minh họa ở Mục
						8.5)
					</p>
					<p>
						* Nếu khách hàng quét 01 Mã QR quá 10 lần liên tiếp, Hệ Thống Phần Mềm khuyến mại sẽ tạm thời không nhận thông tin dự thưởng trên Website từ tài khoản Zalo
						của khách hàng trong vòng 24 giờ. Từ lần quét Mã QR bị trùng thứ 11 trở đi, khách hàng sẽ nhận được thông báo trực tiếp tại giao diện Website với nội dung
						như sau: “Tài khoản của bạn đang bị khóa vì sử dụng cùng 1 mã trên 10 lần. Tài khoản sẽ được mở lại sau 24 giờ”.
					</p>
					<p>
						* Nếu khách hàng quét sai Mã QR quá 10 lần liên tiếp, Hệ Thống Phần Mềm khuyến mại sẽ không nhận thêm thông tin dự thưởng trên Website từ tài khoản Zalo của
						khách hàng. Từ lần quét Mã QR bị sai thứ 11 trở đi, khách hàng sẽ nhận được thông báo trực tiếp tại giao diện Website với nội dung như sau: “Tài khoản của
						bạn đang bị khóa vì quét sai mã trên 10 lần”.
					</p>
					<p>
						<strong>8.2. Thời gian, cách thức phát hành bằng chứng xác định trúng thưởng</strong>
					</p>
					<p>
						- Bằng chứng xác định trúng thưởng của chương trình là Mã QR, Mã Dự Thưởng và thông báo trúng thưởng được gửi từ tài khoản Zalo "Nước tăng lực Rockstar" đến
						tài khoản Zalo của số điện thoại khách hàng đăng ký tham gia chương trình. Mã QR được in dưới nắp lon Sản Phẩm Khuyến Mại.
					</p>
					<p>
						- Hệ Thống Phần Mềm của chương trình sẽ mở từ 00 giờ 00 phút ngày 01/07/2024 đến 17 giờ 30 phút ngày 31/08/2024 để khách hàng có thể quét Mã QR tham gia
						chương trình.
					</p>
					<p>
						- Khách hàng sẽ nhận được Mã Dự Thưởng khi quét QR code và sưu tầm đủ 6 ngôi sao may mắn của chương trình. Trong vòng trong vòng 12 giờ (trừ trường hợp bất
						khả kháng) kể từ thời điểm khách hàng nhận được ngôi sao may mắn thứ 6 (riêng vào ngày cuối cùng của chương trình khuyến mại, hệ thống sẽ xử lý trong vòng
						30 phút kể từ thời điểm khách hàng nhận được ngôi sao may mắn thứ 6), khách hàng sẽ nhận được tin nhắn kèm Mã Dự Thưởng của chương trình với nội dung như
						được nêu tại Mục 8.1.
					</p>
					<p>
						<strong>8.3. Quy định về bằng chứng xác định trúng thưởng:</strong>
					</p>
					<p>- Dự kiến có khoảng 17.800.000 Mã QR được phát hành.</p>
					<p>- Mỗi Sản Phẩm Khuyến Mại đều có Mã QR. Mã QR được in dưới nắp khoen lon Sản Phẩm Khuyến Mại.</p>
					<p>- Mã QR phải được khách hàng quét tham gia trong thời gian khuyến mại.</p>
					<p>
						- Tin nhắn chứa Mã Dự Thưởng được gửi từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản Zalo của số điện thoại khách hàng đăng ký tham gia chương
						trình. Khách hàng nhận được Mã Dự Thưởng mỗi lần sưu tầm đủ 6 ngôi sao may mắn. Dự kiến có khoảng 2.970.000 Mã Dự Thưởng được phát hành. Mã Dự Thưởng gồm 8
						ký tự ngẫu nhiên bao gồm chữ in hoa, chữ in thường và số. Tất cả các ký tự đều có vị trí ngẫu nhiên.
					</p>
					<p>
						- Tin nhắn thông báo trúng thưởng được gửi từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản Zalo của số điện thoại khách hàng đăng ký tham gia
						chương trình.
					</p>
					<p>
						<strong>8.4. Thời gian, địa điểm và cách thức xác định trúng thưởng:</strong>
					</p>
					<p>
						Công ty TNHH Nước giải khát Suntory PepsiCo Việt Nam sẽ tổ chức quay số trúng thưởng bằng Hệ Thống Phần Mềm của chương trình để xác định trúng thưởng ngẫu
						nhiên như sau:
					</p>
					<p>
						<strong>a) Đối với Giải may mắn 1 (lượt nạp tiền điện thoại trị giá 10.000 đồng)</strong>
					</p>
					<p>Thời gian để khách hàng thực hiện quét Mã QR để tham gia chương trình là từ 00 giờ 00 phút ngày 01/07/2024 đến 17 giờ 30 phút ngày 31/08/2024.</p>
					<p>
						Chương trình xác định trúng thưởng thông qua hình thức quay số bằng Hệ Thống Phần Mềm của chương trình được lập trình sẵn không có sự can thiệp của con
						người. Khách hàng sẽ được xác định ngẫu nhiên trúng thưởng hoặc không trúng thưởng tối đa 12 giờ kể từ khi khách hàng nhấn nút “NHẬN THƯỞNG NGAY” (riêng vào
						ngày cuối cùng của chương trình khuyến mại, hệ thống sẽ xử lý trong vòng 30 phút kể từ khi khách hàng nhấn nút “NHẬN THƯỞNG NGAY”). Khách hàng sẽ nhận được
						thông báo trúng thưởng Giải may mắn 1 từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản Zalo của số điện thoại khách hàng đăng ký tham gia chương
						trình (xem ảnh minh họa tại Mục 8.5a).
					</p>
					<p>
						<strong>b) Đối với Giải may mắn 2 (lượt nạp tiền điện thoại trị giá 20.000 đồng)</strong>
					</p>
					<p>
						Khách hàng thực hiện quét Mã QR và tích lũy các ngôi sao may mắn được hiển thị tại giao diện người chơi để được nạp tiền điện thoại 20.000 VNĐ. Theo đó, cứ
						mỗi lần người chơi sưu tầm đủ 6 ngôi sao may mắn, thì trong vòng 12 giờ (trừ trường hợp bất khả kháng) kể từ thời điểm khách hàng nhận được ngôi sao may mắn
						thứ 6 (riêng vào ngày cuối cùng của chương trình khuyến mại, hệ thống sẽ xử lý trong vòng 30 phút kể từ thời điểm khách hàng nhận được ngôi sao may mắn thứ
						6), khách hàng sẽ nhận được thông báo trúng thưởng Giải may mắn 2 từ tài khoản Zalo "Nước tăng lực Rockstar". Đồng thời, khách hàng sẽ nhận được một Mã Dự
						Thưởng để tham gia chương trình quay số may mắn để có cơ hội trúng Giải Đặc Biệt của chương trình.
					</p>
					<p>
						Tin nhắn với nội dung: "Chúc mừng bạn đã sưu tầm đủ 6 ngôi sao may mắn và trúng giải nạp tiền điện thoại trị giá 20.000 VNĐ!!! Đồng thời, bạn đã nhận được
						mã dự thưởng XXXXXXXX để tham gia chương trình quay số may mắn trúng 100.000.000 VNĐ tiền mặt
					</p>
					<p>Tiếp tục quét Mã QR và sưu tầm đủ 6 ngôi sao may mắn mới để được nạp thêm tiền điện thoại 20.000 VNĐ nhé!”</p>
					<p>
						Sau mỗi lần khách hàng đủ điều kiện nhận Giải may mắn 2, hệ thống sẽ làm mới giao diện Website của người chơi với hình ảnh 6 ngôi sao may mắn còn trống,
						người chơi cần quét Mã QR mới để tiếp tục sưu tầm đủ 6 ngôi sao may mắn mới để được tiếp tục nhận các Giải may mắn 2 tiếp theo. Mỗi số điện thoại được tham
						gia nhiều lần, cho tới khi hết 400.000 Giải may mắn 2 theo cơ cấu của chương trình. Trường hợp không trúng Giải may mắn 2, khách hàng sưu tầm đủ 6 ngôi sao
						may mắn vẫn nhận Mã Dự Thưởng để tham gia quay số may mắn Giải Đặc Biệt.
					</p>
					<p>
						Nội dung tin nhắn như sau: "Chúc mừng bạn đã sưu tầm đủ 6 ngôi sao may mắn và nhận được mã dự thưởng XXXXXXXX để tham gia chương trình quay số may mắn trúng
						100.000.000 VNĐ tiền mặt!!! Tiếp tục quét Mã QR và sưu tầm đủ 6 ngôi sao may mắn mới để có thêm nhiều mã dự thưởng nhé!”
					</p>
					<p>Hình ảnh giao diện màn hình có ngôi sao may mắn:</p>
					<div className="img">
						<img src={tnc9} />
					</div>
					<p>Hình ảnh giao diện màn hình thu thâp đủ 6 ngôi sao may mắn và trúng Giải may mắn 2 (xem ảnh minh họa tại Mục Điều 8.5b).</p>
					<p>
						<strong>c) Giải Đặc Biệt:</strong>
					</p>
					<p>
						Đối với Giải đặc biệt, các khách hàng đã quét Mã QR và tích lũy đủ tối thiểu 6 ngôi sao may mắn và nhận được Mã Dự Thưởng sẽ được tham gia chương trình quay
						số trúng thưởng. Việc xác định trúng thưởng được thực hiện thông qua hình thức quay số bằng Hệ Thống Phần Mềm của chương trình để lựa chọn ngẫu nhiên người
						trúng thưởng thông qua Mã Dự Thưởng mà khách hàng nhận được mỗi lần sưu tầm đủ 6 ngôi sao may mắn. Việc quay số được tổ chức định kỳ thành 05 đợt, chi tiết
						như sau:
					</p>

					<div className="table">
						<table>
							<thead>
								<tr>
									<td>Đợt quay thưởng </td>
									<td>Thời gian quay số xác định trúng thưởng </td>
									<td>Thời gian khách hàng nhận Mã Dự Thưởng (tính theo thời gian ghi nhận tại Hệ Thống Phần Mềm chương trình) </td>
									<td>Giải Đặc biệt</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Đợt 1</td>
									<td>15 giờ ngày 16/07/2024</td>
									<td>Thời gian từ 00 giờ 00 phút ngày 01/07/2024 đến 17 giờ 30 phút ngày 11/07/2024</td>
									<td>02</td>
								</tr>
								<tr>
									<td>Đợt 2</td>
									<td>15 giờ ngày 30/07/2024</td>
									<td>Thời gian từ 00 giờ 00 phút ngày 01/07/2024 đến 17 giờ 30 phút ngày 25/07/2024</td>
									<td>02</td>
								</tr>
								<tr>
									<td>Đợt 3</td>
									<td>15 giờ ngày 13/08/2024</td>
									<td>Thời gian từ 00 giờ 00 phút ngày 01/07/2024 đến 17 giờ 30 phút ngày 08/08/2024 </td>
									<td>02</td>
								</tr>
								<tr>
									<td>Đợt 4</td>
									<td>15 giờ ngày 27/08/2024</td>
									<td>Thời gian từ 00 giờ 00 phút ngày 01/07/2024 đến 17 giờ 30 phút ngày 22/08/2024</td>
									<td>02</td>
								</tr>
								<tr>
									<td>Đợt 5</td>
									<td>15 giờ ngày 06/09/2024</td>
									<td>Thời gian từ 00 giờ 00 phút ngày 01/07/2024 đến 18 giờ 00 phút ngày 31/08/2024</td>
									<td>02</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p>
						- Khách hàng có thể có nhiều Mã Dự Thưởng bằng cách quét Mã QR và sưu tầm nhiều bộ 6 ngôi sao may mắn. Mỗi lần đạt đủ 6 ngôi sao may mắn tương ứng với một
						Mã Dự Thưởng.
					</p>
					<p>
						- Mã Dự Thưởng đã trúng Giải Đặc Biệt sẽ không được tham gia quay số ở các đợt tiếp theo. Tất cả Mã Dự Thưởng không trúng thưởng từ đợt trước sẽ được tiếp
						tục tham gia quay số trúng thưởng vào đợt kế tiếp.
					</p>
					<p>
						Buổi quay số trúng thưởng sẽ diễn ra tại văn phòng Công ty TNHH Nước giải khát Suntory PepsiCo Việt Nam - Tầng 03, Cao ốc Sheraton, Số 88, Đường Đồng Khởi,
						Phường Bến Nghé, Quận 1, Thành phố Hồ Chí Minh, Việt Nam.
					</p>
					<p>
						Buổi quay số có sự chứng kiến của đại diện Công ty và đại diện khách hàng tham dự chương trình. Công ty sẽ thực hiện quay số trúng thưởng để xác định 10
						khách hàng may mắn nhận Giải đặc biệt từ chương trình. Kết quả buổi quay số được lập thành biên bản và có chữ ký xác nhận của các bên tham gia chứng kiến.
					</p>
					<p>
						Trong thời gian khuyến mại, CÔNG TY TNHH NƯỚC GIẢI KHÁT SUNTORY PEPSICO VIỆT NAM sẽ thường xuyên cập nhật số lượng Giải may mắn 1 và Giải may mắn 2 còn lại
						và công bố công khai tại Mục “Trang chủ” tại Website chương trình cho khách hàng được biết.
					</p>
					<p>Kết quả công bố trên trên Facebook của Nhãn hàng Nước tăng lực Rockstar (https://www.facebook.com/rockstarvietnam).</p>
					<p>
						<strong>8.5. Thông báo trúng thưởng:</strong>
					</p>
					<p>a) Đối với Giải may mắn 1 (Lượt nạp tiền điện thoại trị giá 10.000 đồng/lượt)</p>
					<p>
						Trong vòng 12 giờ (trừ trường hợp bất khả kháng) kể từ thời điểm khách hàng nhấn nút “NHẬN THƯỞNG NGAY” (riêng vào ngày cuối cùng của chương trình khuyến
						mại, hệ thống sẽ xử lý trong vòng 30 phút kể từ thời điểm khách hàng nhấn nút “NHẬN THƯỞNG NGAY”), khách hàng sẽ nhận được thông báo xác định trúng giải
						hoặc không trúng giải trực tiếp tại giao diện Website và từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản Zalo của số điện thoại khách hàng đăng ký
						tham gia chương trình với nội dung như bên dưới. Theo đó, nếu trúng giải nạp điện thoại, khách hàng thực hiện nhấn vào biểu tượng “Cho phép” chương trình
						nhận thông tin số điện thoại của khách hàng trúng giải, hệ thống sẽ trực tiếp nạp tiền vào số điện thoại đó.
					</p>

					<div className="table">
						<table>
							<thead>
								<tr>
									<td>Trường hợp </td>
									<td>Thông báo tại Website </td>
									<td>Thông báo từ tài khoản Zalo "Nước tăng lực Rockstar"</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>Trường hợp trúng Giải may mắn 1 và trúng ngôi sao may mắn:</td>
									<td>
										Nếu người tham gia đã cho phép truy cập số điện thoại
										<div className="img mt-10 mb-10">
											<img src={tnc8} />
										</div>
										Nếu người tham gia chưa cho phép truy cập số điện thoại, thông tin trúng giải sẽ hiện ra trong 1 giây và chuyển đến phần yêu cầu cho phép
										truy cập số điện thoại:
										<div className="img mt-10 mb-10">
											<img src={tnc8} />
										</div>
										<div className="img mt-10 mb-10">
											<img src={tnc15} />
										</div>
									</td>
									<td>
										"Chúc mừng bạn trúng giải nạp tiền điện thoại 10.000 VNĐ và nhận được 1 ngôi sao may mắn!!! Tiếp tục quét Mã QR và sưu tầm đủ 6 ngôi sao may
										mắn để được nạp tiền điện thoại 20.000 VNĐ nhé!"
									</td>
								</tr>
								<tr>
									<td>Trường hợp không trúng Giải may mắn 1 nhưng trúng ngôi sao may mắn: </td>
									<td>
										<div className="img">
											<img src={tnc9} />
										</div>{" "}
									</td>
									<td>Không có tin nhắn</td>
								</tr>
								<tr>
									<td>Trường hợp không trúng Giải may mắn 1 và cũng không trúng ngôi sao may mắn:</td>
									<td>
										<div className="img">
											<img src={tnc10} />
										</div>{" "}
									</td>
									<td>Không có tin nhắn</td>
								</tr>
								<tr>
									<td>Trường hợp quét 1 mã QR code từ 02 lần trở lên</td>
									<td>
										<div className="img">
											<img src={tnc10} />
										</div>{" "}
									</td>
									<td> </td>
								</tr>
								<tr>
									<td>Trường hợp scan 1 mã QR code trên 10 lần </td>
									<td>
										<div className="img">
											<img src={tnc11} />
										</div>{" "}
									</td>
									<td></td>
								</tr>
								<tr>
									<td>Trường hợp quét sai mã QR code trên 10 lần </td>
									<td>
										<div className="img">
											<img src={tnc14} />
										</div>{" "}
									</td>
									<td></td>
								</tr>
							</tbody>
						</table>
					</div>

					<p>
						<strong>b) Đối với Giải may mắn 2 (Lượt nạp tiền điện thoại trị giá 20.000 đồng/lượt)</strong>
					</p>
					<p>
						Cứ mỗi lần khách hàng quét Mã QR và tích lũy đủ 6 ngôi sao may mắn, thì trong vòng 12 giờ (trừ trường hợp bất khả kháng) kể từ thời điểm khách hàng nhận
						được ngôi sao may mắn thứ 6 (riêng vào ngày cuối cùng của chương trình khuyến mại, hệ thống sẽ xử lý trong vòng 30 phút kể từ thời điểm khách hàng nhận được
						ngôi sao may mắn thứ 6), khách hàng sẽ nhận được thông báo trúng thưởng Giải may mắn 2 trực tiếp tại giao diện Website và từ tài khoản Zalo "Nước tăng lực
						Rockstar" với nội dung như bên dưới. Theo đó, nếu trúng giải nạp điện thoại, khách hàng thực hiện nhấn vào biểu tượng “Cho phép” chương trình nhận thông tin
						số điện thoại của khách hàng trúng giải, hệ thống sẽ trực tiếp nạp tiền vào số điện thoại đó.{" "}
					</p>
					<div className="table full">
						<table>
							<thead>
								<tr>
									<td>Thông báo tại Website </td>
									<td>Thông báo từ tài khoản Zalo "Nước tăng lực Rockstar"</td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td>
										Nếu người tham gia đã cho phép truy cập điện thoại:
										<div className="img mt-10 mb-10">
											<img src={tnc13_1} />
										</div>{" "}
										Nếu người tham gia chưa cho phép truy cập số điện thoại, thông tin trúng giải sẽ hiện ra trong 1 giây và chuyển đến phần yêu cầu cho phép
										truy cập số điện thoại:
										<div className="img mt-10 mb-10">
											<img src={tnc13} />
										</div>{" "}
										<div className="img mt-10 mb-10">
											<img src={tnc15} />
										</div>{" "}
									</td>
									<td>
										Trường hợp tích lũy đủ 6 ngôi sao may mắn và được nhận giải may mắn 2: Khách hàng nhận được nội dung tin nhắn như sau: "Chúc mừng bạn đã sưu
										tầm đủ 6 ngôi sao may mắn và trúng giải nạp tiền điện thoại trị giá 20.000 VNĐ!!! Đồng thời, bạn đã nhận được mã dự thưởng XXXXXXXX để tham
										gia chương trình quay số may mắn trúng 100.000.000 VNĐ tiền mặt Tiếp tục quét Mã QR và sưu tầm đủ 6 ngôi sao may mắn mới để được nạp thêm
										tiền điện thoại 20.000 VNĐ nhé!”
									</td>
								</tr>
								<tr>
									<td>
										<div className="img">
											<img src={tnc13_2} />
										</div>
									</td>
									<td>
										Trường hợp không trúng Giải may mắn 2, khách hàng sưu tầm đủ 6 ngôi sao may mắn vẫn nhận Mã Dự Thưởng để tham gia quay số may mắn Giải Đặc
										Biệt. Nội dung tin nhắn như sau: "Chúc mừng bạn đã sưu tầm đủ 6 ngôi sao may mắn và nhận được mã dự thưởng XXXXXXXX để tham gia chương trình
										quay số may mắn trúng 100.000.000 VNĐ tiền mặt!!! Tiếp tục quét Mã QR và sưu tầm đủ 6 ngôi sao may mắn mới để có thêm nhiều mã dự thưởng
										nhé!”
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<p>c) Giải Đặc Biệt:</p>
					<p>
						Trong vòng tối đa 3 ngày làm việc (không tính Thứ 7, Chủ nhật và các ngày lễ) kể từ ngày quay số xác định trúng thưởng, đại diện của Công ty TNHH Nước giải
						khát Suntory PepsiCo Việt Nam sẽ liên lạc với khách hàng bằng cách gọi điện từ tổng đài 19001220 và nhắn tin từ tài khoản Zalo "Nước tăng lực Rockstar" đến
						tài khoản Zalo của số điện thoại khách hàng đăng ký tham gia chương trình để thông báo trúng thưởng và hướng dẫn khách hàng chuẩn bị hồ sơ nhận thưởng theo
						quy định tại thể lệ này để mang theo và cung cấp cho SPVB khi nhận giải thưởng.
					</p>
					<p>
						Khách hàng cũng có thể theo dõi kết quả trúng thưởng công bố trên trên trang Facebook của Nhãn hàng Nước tăng lực Rockstar
						(https://www.facebook.com/rockstarvietnam) và tài khoản Zalo chính thức “Nước tăng lực Rockstar”. Cuộc gọi có thể được ghi âm để xác nhận việc thực hiện
						thông báo trúng giải.
					</p>
					<p>
						Tin nhắn Zalo thông báo trúng Giải Đặc Biệt được gửi từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản Zalo của số điện thoại khách hàng đăng ký tham
						gia chương trình với nội dung như sau:
					</p>
					<p>
						"Tuyệt vời!!! Bạn đã giành được giải thưởng lớn nhất từ ROCKSTAR là 100.000.000 VNĐ. Vui lòng GIỮ TIN NHẮN NÀY và cung cấp chính xác và đầy đủ thông tin cá
						nhân để nhận quà nhé!"
					</p>

					<p>8.6. Thời gian địa điểm, cách thức và thủ tục trao thưởng</p>
					<p>a) Đối với Giải may mắn 1 và Giải may mắn 2</p>
					<p>
						- Trong vòng 24 giờ (trừ trường hợp bất khả kháng) kể từ khi nhận được thông báo trúng thưởng từ Website hoặc từ tài khoản Zalo "Nước tăng lực Rockstar" đến
						tài khoản Zalo của số điện thoại khách hàng đăng ký tham gia chương trình, khách hàng sẽ được nhà mạng nạp tiền trực tiếp vào tài khoản điện thoại.
					</p>
					<p>- Giải thưởng lượt nạp tiền điện thoại chỉ áp dụng cho các thuê bao của 06 nhà mạng gồm: Viettel, MobiFone, Vinaphone, Vietnamobile, Gtel & Itel.</p>
					<p>
						- Trong trường hợp thẻ nạp tự động không thành công do khách hàng thay đổi thông tin nhà mạng nhưng giữ nguyên số điện thoại, hoặc thuê bao trả sau chưa
						đăng ký EZpay, hệ thống sẽ gửi tin nhắn mã nạp tiền điện thoại 10.000 đồng hoặc 20.000 đồng (tùy giải thưởng mà khách hàng trúng) từ dịch vụ tin nhắn thương
						hiệu có hiển thị “PEPSICO VN”:
					</p>
					<p>
						* “Quy khach nhan duoc ma nap tien dien thoai tri gia 10.000 dong tu chuong trinh Quet Ngay Co Hoi Trung Lien Tay cua Rockstar. Ma nap tien: xxxxxxxxxxxxxx”
						hoặc
					</p>
					<p>
						* “Quy khach nhan duoc ma nap tien dien thoai tri gia 20.000 dong tu chuong trinh Quet Ngay Co Hoi Trung Lien Tay cua Rockstar. Ma nap tien:
						xxxxxxxxxxxxxx”.
					</p>
					<p>
						Khách hàng tự nạp tiền điện thoại dựa trên mã nạp tiền từ tin nhắn nhận được từ tổng đài PEPSICO VN. Trong trường hợp này thời hạn sử dụng Mã nạp tiền điện
						thoại là 12 tháng kể từ khi nhận giải (Ghi chú: mã "xxxxxxxxxxxxxx" là minh họa cho mã nạp tiền điện thoại mà khách hàng nhận được, mã thực tế sẽ thay đổi
						theo từng khách hàng trúng thưởng).
					</p>
					<p>
						Trong vòng 24 giờ kể từ khi xác định trúng thưởng, nếu khách hàng vẫn chưa được nạp tiền vào tài khoản hoặc chưa nhận được tin nhắn chứa mã thẻ nạp thì
						khách hàng liên hệ tổng đài 19001220 để được hỗ trợ.
					</p>
					<p>b) Đối với Giải Đặc Biệt:</p>
					<p>
						- Trong vòng 5 ngày kể từ khi khách hàng nhận được thông báo trúng thưởng từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản Zalo của số điện thoại
						khách hàng đăng ký tham gia chương trình, Công ty TNHH nước giải khát Suntory PepsiCo Việt Nam sẽ gọi điện thông báo xác nhận với khách hàng về việc trúng
						thưởng từ tổng đài 19001220 và hướng dẫn khách hàng thủ tục nhận thưởng, thống nhất địa điểm trao thưởng theo thông tin số điện thoại mà khách hàng đã đăng
						ký tham gia chương trình.
					</p>
					<p>- Hạn chót để khách hàng trúng thưởng nhận Giải Đặc Biệt là 45 ngày kể từ ngày kết thúc chương trình khuyến mại.</p>
					<p>
						- Thuế thu nhập cá nhân từ trúng thưởng (nếu có) theo quy định của pháp luật hiện hành sẽ do Công ty TNHH nước giải khát Suntory PepsiCo Việt Nam chịu. Công
						ty TNHH nước giải khát Suntory PepsiCo Việt Nam sẽ đại diện khách hàng kê khai và nộp khoản thuế này cho Nhà nước theo quy định của pháp luật về thuế.
					</p>
					<p>
						- Thời gian trao thưởng được thực hiện trong giờ làm việc từ 08 giờ 00 phút đến 17 giờ 00 phút các ngày từ Thứ 2 đến Thứ 6 theo thỏa thuận giữa Công ty TNHH
						nước giải khát Suntory PepsiCo Việt Nam và người trúng thưởng. Khi nhận thưởng, khách hàng được yêu cầu xuất trình, cung cấp các chứng từ và hoàn thành các
						thủ tục trao giải như sau:
					</p>
					<p>+ Người nhận thưởng phải từ 18 tuổi trở lên.</p>
					<p>
						+ Người nhận thưởng cung cấp bản sao chứng thực có giá trị trong vòng 6 tháng của CMND hoặc CCCD hoặc Hộ Chiếu hoặc Bằng Lái Xe (mang theo bản gốc để đối
						chiếu).
					</p>
					<p>
						+ Tin nhắn thông báo Mã Dự Thưởng từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản Zalo của số điện thoại khách hàng đăng ký tham gia chương trình.
					</p>
					<p>
						+ Tin nhắn thông báo trúng thưởng từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản Zalo của số điện thoại khách hàng đăng ký tham gia chương trình.
					</p>
					<p>
						+ Số tài khoản ngân hàng chính chủ của người trúng thưởng (bản gốc giấy xác nhận chủ tài khoản do ngân hàng phát hành) để Công ty TNHH nước giải khát
						Suntory PepsiCo Việt Nam thực hiện chuyển khoản số tiền thưởng theo quy định.
					</p>
					<p>+Người trúng giải có trách nhiệm ký tên vào biên bản trao thưởng khi nhận giải thưởng.</p>
					<p>
						- Giải Đặc Biệt sẽ được trao bằng hình thức chuyển khoản qua ngân hàng đến số tài khoản chính chủ của người trúng thưởng. Chi tiết về địa điểm và theo thời
						gian trao giải theo thống nhất giữa Công ty TNHH nước giải khát Suntory PepsiCo Việt Nam và khách hàng trúng thưởng
					</p>
					<p>- Thời hạn cuối cùng trao giải là 17 giờ ngày 15/10/2024.</p>
				</div>
				<div className="bl">
					<h4>9. Đầu mối giải đáp thắc mắc cho khách hàng về các vấn đề liên quan đến Chương trình khuyến mại (người liên hệ, điện thoại...)</h4>
					<p>
						- Mọi thắc mắc liên quan đến chương trình khuyến mại này, khách hàng liên hệ số điện thoại 19001220 hoặc truy cập trang chủ trang Facebook của Nhãn hàng
						Nước tăng lực Rockstar (https://www.facebook.com/rockstarvietnam) và tài khoản Zalo chính thức “Nước tăng lực Rockstar” để biết thêm thông tin.
					</p>
				</div>
				<div className="bl">
					<h4>10. Trách nhiệm công bố thông tin:</h4>
					<p>
						- Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam sẽ thông báo đầy đủ chi tiết nội dung của thể lệ chương trình khuyến mại này tại trang chủ Facebook
						của Nhãn hàng Nước tăng lực Rockstar (https://www.facebook.com/rockstarvietnam) và tài khoản Zalo chính thức “Nước tăng lực Rockstar” và/hoặc trên áp phích
						tại các điểm bán hàng trong thời gian diễn ra chương trình khuyến mại. - Thông tin về kết quả trúng thưởng sẽ được thông báo bằng cách đăng tải danh sách
						trúng thưởng (số điện thoại những người trúng thưởng) trên trang chủ Facebook của Nhãn hàng Nước tăng lực Rockstar
						(https://www.facebook.com/rockstarvietnam) hoặc trên phương tiện truyền thông đại chúng và thông báo trực tiếp cho khách hàng trúng thưởng. Để bảo vệ thông
						tin cá nhân của khách hàng trúng giải, khi công bố danh sách trúng thưởng, Công ty có thể ẩn một phần số điện thoại và tên của khách hàng trúng thưởng.
					</p>
				</div>
				<div className="bl">
					<h4>11. Các quy định khác:</h4>
					<p>
						- Phù hợp với quy định của pháp luật về bảo vệ dữ liệu cá nhân, Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam chỉ có thể thực hiện nạp các Giải may
						mắn vào số điện thoại của Khách Hàng khi Khách Hàng cho phép Nước tăng Lực Rockstar truy cập số điện thoại bằng cách nhấn vào nút “Cho phép” trong giao
						diện:
					</p>
					<div className="img">
						<img src={tnc15} alt="" />
					</div>
					<p>
						- Trường hợp, nếu Khách Hàng không “Cho phép” truy cập số điện thoại thì các Giải may mắn này sẽ tiếp tục đưa vào số lượng giải thưởng của chương trình và
						được trao đến các Khách Hàng khác thỏa mãn điều kiện chương trình.
					</p>

					<p>
						- Khách hàng khi tham gia đổi thưởng cần giữ lại Tin nhắn chữa mã dự thưởng, thông báo trúng thưởng từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản
						Zalo của số điện thoại khách hàng đăng ký tham gia chương trình.
					</p>
					<p>
						- Nếu khách hàng trúng thưởng đồng ý, Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam và các bên liên quan sẽ sử dụng thông tin cá nhân gồm tên, hình
						ảnh, số điện thoại nhằm mục đích thương mại hoặc phi thương mại của SPVB mà không yêu cầu SPVB thanh toán bất kỳ chi phí sử dụng nào.{" "}
					</p>
					<p>
						- Nhân viên của Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam và nhân viên của các đại lý, nhà phân phối, nhà quảng cáo, nhà cung cấp chịu trách
						nhiệm in ấn, vận chuyển, sản xuất Sản Phẩm Khuyến Mại, hoặc tham gia tư vấn, kiểm toán, kiểm soát, hoặc hoặc phát triển phần mềm, các hoạt động kỹ thuật
						phục vụ chương trình khuyến mại không được tham gia chương trình này.
					</p>
					<p>
						- Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam sẽ chịu trách nhiệm ký kết hợp đồng với bên in ấn để đảm bảo khâu in ấn và đưa bằng chứng xác định
						trúng thưởng vào Sản Phẩm Khuyến Mại được thực hiện đúng theo thể lệ này và các quy định của pháp luật Việt Nam.{" "}
					</p>
					<p>
						- Trường hợp bằng chứng xác định trúng thưởng do Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam phát hành kèm theo Sản Phẩm Khuyến Mại có sai sót do
						lỗi của Công ty và khách hàng trúng thưởng với bằng chứng xác định trúng thưởng đó thì Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam có trách nhiệm
						trao giải thưởng đó cho khách hàng.{" "}
					</p>
					<p>
						- Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam chỉ chịu trách nhiệm cho số lượng Sản Phẩm Khuyến Mại, tổng giá trị giải thưởng được ghi nhận trong
						chương trình khuyến mại này.
					</p>
					<p>
						- Khách hàng nhận thưởng phải là chủ nhân của số điện thoại đã đăng ký tham gia chương trình theo hướng dẫn tại thể lệ này. Trường hợp khác biệt, thì phải
						có ủy quyền hợp lệ theo quy định của pháp luật.
					</p>
					<p>- Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam sẽ không chịu trách nhiệm đối với các trường hợp như sau:</p>
					<p>
						* Khách hàng tham gia chương trình không giữ lại tin nhắn chữa mã dự thưởng, thông báo trúng thưởng từ tài khoản Zalo "Nước tăng lực Rockstar" đến tài khoản
						Zalo của số điện thoại khách hàng đăng ký tham gia chương trình;
					</p>
					<p>* Số điện thoại đăng ký tài khoản Zalo của quý khách không phải là số điện thoại nhận thưởng các Giải may mắn. </p>
					<p>* Khách hàng không cung cấp được số tài khoản ngân hàng chính chủ của người trúng thưởng. </p>
					<p>
						* Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam không thể liên hệ hoặc không thể gửi thông báo trúng thưởng hay giải thưởng cho khách hàng tham gia
						chương trình vì lí do Khách hàng cung cấp thông tin không đúng hoặc thông tin Khách hàng cung cấp không liên lạc được, hoặc không nhận được thông tin từ
						khách hàng tham gia gửi về chương trình trong thời gian yêu cầu.
					</p>
					<p>
						- Sau khi kết thúc thời hạn trao thưởng (17 giờ ngày 15/10/2024), Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam sẽ không nhận đổi thêm bất kỳ giải
						thưởng nào và có quyền từ chối trao giải cho người trúng thưởng. Số giải thưởng không có người trúng thưởng sẽ được xử lý theo quy định của pháp luật.
					</p>
					<p>
						- Trong trường hợp có khiếu nại, tranh chấp Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam sẽ trực tiếp giải quyết theo đúng quy định của thể lệ
						chương trình khuyến mại đã đăng ký và pháp luật Việt Nam hiện hành.
					</p>
					<p>
						- Đối với những giải thưởng không có người trúng thưởng Công ty TNHH Nước Giải Khát Suntory PepsiCo Việt Nam phải có trách nhiệm trích nộp 50% giá trị đã
						công bố của giải thưởng đó vào ngân sách nhà nước theo quy định của pháp luật.
					</p>
				</div>
			</div>
		</div>
	);
}
