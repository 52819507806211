import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ic1 from "../../assets/images/ic1.png";
import ic1_2 from "../../assets/images/ic1_2.png";
import ic2 from "../../assets/images/ic2.png";
import ic2_2 from "../../assets/images/ic2_2.png";
import ic3 from "../../assets/images/ic3.png";
import ic3_2 from "../../assets/images/ic3_2.png";
import ic4 from "../../assets/images/ic4.png";
import ic4_2 from "../../assets/images/ic4_2.png";
import logo from "../../assets/images/logo.png";
import Logo from "../../components/Logo/Logo";
import "./LayoutStructure.scss";

export default function LayoutStructure({ children, bodyClass }) {
    
    const getOffset = () => {
        const header = document.querySelector(".page-head");
        if (window.scrollY > 50) {
            header.classList.add("active");
        } else {
            header.classList.remove("active");
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        window.addEventListener("scroll", getOffset);
    }, []);
    return (
        <div className={`LayoutStructure ${bodyClass}`}>
            <div className="container  ">
                <div className="page-content App">
                    <div className="page-head ">
                        <div className="logo-brand">
                            <Logo logo={logo} />
                        </div>
                    </div>
                    <div className="body-content">{children}</div>
                </div>
                {/* <div className="bottom-menu">
                    <ul>
                        <li>
                            <Link to={"/#!"} className="active">
                                <div className="item">

                                <div className="ic">
                                    <div className="img">
                                        <img src={ic1}  className="ic1"/>
                                        <img src={ic1_2} className="ic2" />
                                    </div>
                                </div>
                                <span>Trang chủ</span>
                                </div>

                            </Link>
                        </li>
                        <li>
                            <Link to={"/#!"}>
                                <div className="item">

                                <div className="ic">
                                    <div className="img">
                                        <img src={ic2}  className="ic1"/>
                                        <img src={ic2_2}className="ic2" />
                                    </div>
                                </div>
                                <span>Về OA</span>
                            </div>
                                
                                </Link>
                        </li>
                        
                        <li>
                            <Link to={"/#!"} >
                            <div className="item">

                            <div className="ic">
                                    <div className="img">
                                        <img src={ic3}  className="ic1"/>
                                        <img src={ic3_2} className="ic2" />
                                    </div>
                                </div>
                                <span>Thể lệ</span></div></Link>
                        </li>
                        
                        <li>
                            <Link to={"/#!"}>
                            <div className="item">

                            <div className="ic">
                                    <div className="img">
                                        <img src={ic4}  className="ic1"/>
                                        <img src={ic4_2} className="ic2" />
                                    </div>
                                </div>
                                <span>Cá nhân</span></div></Link>
                        </li>
                        
                        
                    </ul>
                </div> */}
                {/* <div className="footer">Footer</div> */}
            </div>
        </div>
    );
}
