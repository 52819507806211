import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./assets/scss/view.scss";
import App from "./App";
import { RecoilRoot } from "recoil";
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById("root"));
import * as ga4 from "./libs/ga/ga4";
import * as ga4Pepsi from "./libs/ga/ga4-pepsi";

const domainName = window.location.origin;
if (domainName.indexOf("rskm.vn") >= 0) ga4.initialize();
else ga4Pepsi.initialize();

root.render(
	<React.StrictMode>
		<RecoilRoot>
			<HelmetProvider>
				<App />
			</HelmetProvider>
		</RecoilRoot>
	</React.StrictMode>
);
