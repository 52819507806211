import sha256 from "js-sha256";
import { http_request } from "../utils/base";

class AdtimaApi {
	constructor() {
		this.url_api = process.env.REACT_APP_ADTIMABOX_API_URL || "";
		this.url_public_api = process.env.REACT_APP_ADTIMABOX_PUBLIC_API_URL || "";
		this.campaign_id = process.env.REACT_APP_CAMPAIGN_ID || "";
		this.campaign_secret_key = process.env.REACT_APP_CAMPAIGN_SECRET_KEY || "";
		this.challengeId = process.env.REACT_APP_CHALLENGE_ID || "";
	}

	async login(data) {
		try {
			const url = `${this.url_api}/api/auth/login`;

			const params = { url, method: "POST", data: data };
			const result = await http_request(params);

			return result;
		} catch (error) {
			console.log("Error login :>> ", error);
			return error;
		}
	}

	async loginWith2Authen(data) {
		try {
			const url = `${this.url_api}/api/auth/sso-confirm-2fa`;

			const params = { url, method: "POST", data: data };
			const result = await http_request(params);

			return result;
		} catch (error) {
			console.log("Error loginWith2Authen :>> ", error);
			return error;
		}
	}

	async getCPInfo(token) {
		try {
			const url = `${this.url_public_api}/digital-api/digital-campaign/detail/${this.campaign_id}`;
			
			const authorization = `Bearer ${token}`;

			const params = { url, headers: { authorization }, method: "GET" };
			const result = await http_request(params);

			if (result?.statusCode !== 200 || !result?.data) return null;
			return result?.data;
		} catch (error) {
			console.log("Error getCPInfo :>> ", error);
			return null;
		}
	}

	async getWinners(token, roundId) {
		try {
			const url = `${this.url_api}/api/digital-campaign-winner-rounds?page=0&limit=100&filters%5Band%5D%5Bdigital_campaign_id%5D=${this.campaign_id}&filters%5Band%5D%5Bdigital_campaign_round_id%5D=${roundId}&sorts%5B0%5D%5BsortKey%5D=_id&sorts%5B0%5D%5BsortValue%5D=ASC`;

			const authorization = `Bearer ${token}`;

			const params = { url, headers: { authorization }, method: "GET" };
			const result = await http_request(params);

			if (result?.statusCode !== 200) return result?.message;
			return result?.data?.list || [];
		} catch (error) {
			console.log("Error getCPInfo :>> ", error);
			return error;
		}
	}

	async spinningLuckyUser(token) {
		try {
			const url = `${this.url_api}/api/digital-campaign-codes/resultLuckyUser`;

			const authorization = `Bearer ${token}`;
			const params = { url, headers: { authorization }, method: "POST", data: { campaignId: this.campaign_id } };
			const result = await http_request(params);

			return result;
		} catch (error) {
			console.log("Error spinningLuckyUser :>> ", error);
			return error;
		}
	}

	async getQuotaGifts() {
		try {
			const time = new Date().getTime();
			const hash = sha256.create();
			hash.update(this.campaign_id + this.campaign_secret_key + time);
			const mac = hash.hex();

			const url =
				this.url_public_api +
				`/digital-api/digital-campaign-gifts/listGifts?limit=${10}&page=${0}&campaignId=${
					this.campaign_id
				}&mac=${mac}&time=${time}`;

			const params = { url, method: "GET" };

			const result = await http_request(params);

			if (result?.statusCode == 200) return result?.data?.record || [];
			return null;
		} catch (error) {
			console.log("Error getQuotaGifts :>> ", error);
			return null;
		}
	}
}
export default AdtimaApi;
