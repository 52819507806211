import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import { stagger, useAnimate } from "framer-motion";

import FullLayout from "../../layouts/FullLayout";

import { userTestAtom } from "../../stores/user_test";
import LayoutStructure from "../../components/LayoutStructure/LayoutStructure";
import TnC from "../../components/TnC/TnC";
import GiftQuota from "../../components/GiftQuota/GiftQuota";
import GiftQuotaSpecial from "../../components/GiftQuota/GiftQuotaSpecial";

import "./Home.scss";

import banner from "../../assets/images/100.jpg";
import title from "../../assets/images/title.png";
import product from "../../assets/images/product_2.png";
import p1 from "../../assets/images/p1.png";
import p2 from "../../assets/images/p2_2.png";
import p3 from "../../assets/images/p3_2.png";
import titleTNC from "../../assets/images/55.png";
import logo from "../../assets/images/logo.png";

import constant from "../../utils/constant";
import campaign from "../../utils/campaign";

import AdtimaApi from "../../services/adtima-api";
const AdtimaApiService = new AdtimaApi();

function Home() {
	const location = useLocation();
	const navigate = useNavigate();
	const [scope, animate] = useAnimate();

	const userTest = useRecoilValue(userTestAtom);
	const [isLoading, setIsLoading] = useState(true);
	const [styles1, setStyles1] = useState(null);
	const [styles2, setStyles2] = useState(null);
	const [styles3, setStyles3] = useState(null);

	const domainName = window.location.origin;
	let search = location?.search;

	const isMobile = campaign.isMobile();

	const fetchData = async () => {
		try {
			const quotaGiftInfo = await AdtimaApiService.getQuotaGifts();
			quotaGiftInfo?.forEach((giftInfo) => {
				let percentageValue = campaign.getPercentageData(giftInfo.assignedQuantity, giftInfo.quantity);
				let assignedQuantity = campaign.numberWithCommas(giftInfo.assignedQuantity);
				const quantity = campaign.numberWithCommas(giftInfo.quantity);

				if (giftInfo?._id === process.env.REACT_APP_GIFT_10k_ID) {
					if (giftInfo.assignedQuantity > 600000) {
						percentageValue = "100%"
						assignedQuantity = "600.000";
					}
					setStyles1({
						assignedQuantity: assignedQuantity,
						quantity: quantity,
						style: {
							clipPath: `polygon(0 0, ${percentageValue} 0, ${percentageValue} 100%, 0 100%)`,
						},
					});
				} else if (giftInfo?._id === process.env.REACT_APP_GIFT_20k_ID) {
					if (giftInfo.assignedQuantity > 400000) {
						percentageValue = "100%"
						assignedQuantity = "400.000";
					}
					setStyles2({
						assignedQuantity: assignedQuantity,
						quantity: quantity,
						style: {
							clipPath: `polygon(0 0, ${percentageValue} 0, ${percentageValue} 100%, 0 100%)`,
						},
					});
				} else if (giftInfo?._id === process.env.REACT_APP_GIFT_LUCKYDRAW_ID) {
					setStyles3({
						assignedQuantity: assignedQuantity,
						quantity: quantity,
						style: {
							clipPath: `polygon(0 0, ${percentageValue} 0, ${percentageValue} 100%, 0 100%)`,
						},
					});
				}
			});
		} catch (err) {
			console.log("err :>> ", err);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (domainName.indexOf("rskm.vn") >= 0) {
			const date = moment().format("YYYY-MM-DD HH:mm");
			if (date >= process.env.REACT_APP_CAMPAIGN_START_TIME || !_.isEmpty(userTest)) {
				window.location.href = process.env.REACT_APP_MINI_APP_URL + location.search;
			} else if (date < process.env.REACT_APP_CAMPAIGN_START_TIME) {
				// navigate(constant.ROUTERS.COUNTDOWN + (search ? search : ""), { replace: true });
			}
		} else {
			fetchData();
		}

		animate(
			".animUp",
			{
				y: [20, 0],
				opacity: [0, 1],
			},
			{
				type: "spring",
				delay: stagger(0.1),
			}
		);

		if (isMobile) {
			animate(
				".animScale",
				{
					scale: [0.5, 1],
					opacity: [0, 1],
				},
				{
					type: "spring",
					delay: stagger(0.1),
				}
			);
		}
	}, []);

	return (
		<FullLayout isLoading={isLoading} title="Trang chủ">
			{isMobile && (
				<LayoutStructure bodyClass="home">
					<div className="page" ref={scope}>
						<div className="content-w size-md">
							<div className="wrap">
								<div className="block block1">
									<div className="animUp bl-title">
										<div className="img">
											<img src={title} />
										</div>
									</div>
									<div className="animUp product mb-20">
										<div className="wrap">
											<div className="main">
												<div className="img">
													<img src={product} />
												</div>
											</div>
											<div className="prize p1">
												<div className="img animScale">
													<img src={p1} />
												</div>
											</div>
											<div className="prize p2">
												<div className="img animScale">
													<img src={p2} />
												</div>
											</div>
											<div className="prize p3">
												<div className="img animScale">
													<img src={p3} />
												</div>
											</div>
										</div>
									</div>
									<GiftQuota styles1={styles1} styles2={styles2} styles3={styles3}></GiftQuota>
								</div>
								<div className="tnc animUp">
									<div className="head">
										<div className="img">
											<img src={titleTNC} />
										</div>
									</div>
									<TnC></TnC>
								</div>
							</div>
						</div>
					</div>
				</LayoutStructure>
			)}
			{!isMobile && (
				<LayoutStructure bodyClass="tnc tnc-pc pc">
					<div className="page" ref={scope}>
						<div className="top animUp">
							<div className="main">
								<div className="img">
									<img src={banner} />
								</div>

								<div className="tagline">
									<div className="img animUp">
										<img src={title} />
									</div>
								</div>
								<div className="logo">
									<div className="img animUp">
										<img src={logo} />
									</div>
								</div>
								<div className="top-content">
									<div className="bl1 ">
										<GiftQuotaSpecial styles1={styles1} styles2={styles2} styles3={styles3}></GiftQuotaSpecial>
									</div>
									<div className="bl2 animUp">
										<p>
											*Hình ảnh minh họa các giải thưởng của Chương trình khuyến mại QUÉT NGAY CƠ HỘI TRÚNG LIỀN TAY, diễn ra từ ngày 01/07/2024 đến 17 giờ 30
											phút ngày 31/08/2024.
										</p>
										<p>**Bước 1: Tìm mã QR dưới nắp khoén.</p>
										<p>
											Bước 2: Dùng Zalo quét mã QR (Đảm bảo mã sạch sẽ trước khi quét). Xem thông tin chi tiết về chương trình tại:{" "}
											<a href="https://www.facebook.com/rockstarvietnam" target="_blank">
												www.facebook.com/rockstarvietnam.
											</a>
										</p>
									</div>
								</div>
								<div className="tnc-title">
									<div className="img animUp">
										<img src={titleTNC} />
									</div>
								</div>
							</div>
						</div>
						<TnC></TnC>
					</div>
				</LayoutStructure>
			)}
		</FullLayout>
	);
}

export default Home;
