import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import moment from "moment";
import { useRecoilState, useRecoilValue } from "recoil";

import "./QR.scss";

import FullLayout from "../../layouts/FullLayout";
import constant from "../../utils/constant";

import { userTestAtom } from "../../stores/user_test";

function QRTest() {
	const navigate = useNavigate();
	const location = useLocation();

	const [userTest, setUserTest] = useRecoilState(userTestAtom);

	let search = location?.search;
	let searchParams = new URLSearchParams(search);
	const code = searchParams.get("c") || searchParams.get("C");

	useEffect(() => {
		if (code && /^[a-zA-Z0-9]{8}$/.test(code)) window.location.href = `https://zalo.me/s/3810463578216539035/?env=TESTING&version=68&c=` + code;
		else navigate(constant.ROUTERS.INVALID_CODE);
	}, []);

	return (
		<FullLayout bodyClass="welcome" isLoading={true} title="Quét QR">
			{/* Loading... */}
		</FullLayout>
	);
}

export default QRTest;
